/** @format */

import { createSlice } from '@reduxjs/toolkit';
import { IActionReducerWithCallback } from 'types';

const initialState = {};

const storeSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    actionRequest: state => {
      return {
        ...state,
        error: null,
        loading: true,
      };
    },
    getDataSuccess: (state, { payload }: { payload: any }) => {
      return {
        ...state,
        error: null,
        loading: false,
        ...payload,
      };
    },
    reset: () => initialState,
    requestDataStore: (state, { payload }: { payload: any }) => {
      return {
        ...state,
        ...payload,
      };
    },
    requestDataWithcb: (state, { payload }: IActionReducerWithCallback) => {
      return payload?.callback?.(state, newState => {
        return { ...newState };
      });
    },
  },
});

export const { actionRequest, getDataSuccess, reset, requestDataStore, requestDataWithcb } =
  storeSlice.actions;

export default storeSlice.reducer;
