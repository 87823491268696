/** @format */

import React from 'react';
import { Layout } from 'antd';
import { ActivityIndicator, BreadCrumbs, HeaderCus, SiderCus } from 'components';
import { Route, Routes } from 'react-router-dom';
import { EMODULE_ROUTES } from 'utils';
import {
  CustomerCatalog,
  Notification,
  Promotion,
  Restaurant,
  RestaurantCatalog,
  Support,
  User,
} from 'pages/main/module';
import './styles.scss';

export default function Mainpage() {
  const { Content } = Layout;
  return (
    <Layout className='app-container'>
      <HeaderCus />
      <Layout>
        <SiderCus />
        <Content style={{ padding: 16 }}>
          <BreadCrumbs />
          <React.Suspense fallback={<ActivityIndicator />}>
            <Routes>
              <Route path={`${EMODULE_ROUTES.USERS}`} element={<User />} />

              <Route path={`${EMODULE_ROUTES.RESTAURANT}`} element={<Restaurant />} />

              <Route
                path={`${EMODULE_ROUTES.RESTAURANT_CATALOG}`}
                element={<RestaurantCatalog />}
              />
              <Route path={`${EMODULE_ROUTES.PROMOTION}`} element={<Promotion />} />
              <Route path={`${EMODULE_ROUTES.SUPPORT}`} element={<Support />} />
              <Route path={`${EMODULE_ROUTES.CUSTOMER_CATALOG}`} element={<CustomerCatalog />} />
              <Route path={`${EMODULE_ROUTES.NOTIFICATION}`} element={<Notification />} />
            </Routes>
          </React.Suspense>
        </Content>
      </Layout>
    </Layout>
  );
}
