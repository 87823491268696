/** @format */
export enum EMODULE_USER_ROUTES {
  USER = 'user',
}
export enum EMODULE_ROUTES {
  USERS = 'users',
  RESTAURANT = 'restaurant',
  RESTAURANT_CATALOG = 'restaurant-catalog',
  NOTIFICATION = 'notification',
  PROMOTION = 'promotion',
  CUSTOMER_CATALOG = 'customer-catalog',
  SUPPORT = 'support',
}
