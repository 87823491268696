/**
 * @format
 * @description the hook to handle all authentication's action
 */

import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { EUserType } from 'types';
import { useNotify } from './useNotify';
import { useKey } from './useKey';
import { API_ENDPOINT, DataHelpers, EMODULE_ROUTES, EMODULE_USER_ROUTES } from 'utils';
import { dispatchActions, useUserDispatch } from 'context/UserContext';
import { baseActionRequest } from 'store/actions';
import { userActions } from 'store/types';
import { useCommon, useUser } from 'hooks';
import { message } from 'antd';

export const useAuth = () => {
  const dispatch = useDispatch();
  const { showMessageError: error } = useNotify();
  const { loading, user } = useUser();
  const { onUpdateDataStore } = useCommon();
  const userDispatch = useUserDispatch();

  const { setKeySite, setUserLogin, removeKeySite } = useKey();
  const { t } = useTranslation();

  const onLogin = useCallback(
    async ({ phoneNumber, password, navigate }, callback?: (a: any) => void) => {
      try {
        dispatch(
          baseActionRequest({
            payload: {
              formData: { phoneNumber, password },
              isObject: true,
              endPoint: API_ENDPOINT.LOGIN,
            },
            type: userActions.POST_BASE_ACTIONS,
            callback: async (res: any) => {
              const data = res?.data;
              if (res?.status === 200) {
                const result: any = data?.result?.[0] || {};
                if (result?.role !== 'ADMIN') {
                  message.error('Không có quyền truy cập');
                  return;
                }
                onUpdateDataStore({ store: 'user', payload: result });
                setKeySite({ accessToken: result['accessToken'] });
                setUserLogin({ user: JSON.stringify(result) });
                callLogin({ navigate });
              } else {
                callback?.('Err');
                message.error(data.message || t('error'));
              }
            },
          }),
        );
      } catch (err: any) {
        const isString = DataHelpers.getDataType(err) === 'String';
        message.error(isString ? err : t('error'));
      }
    },
    [error, dispatch, t],
  );

  const callLogin = ({ navigate }) => {
    // getSocketID(() => {
    dispatchActions({ type: EUserType.LOGIN, payload: null }, userDispatch);
    navigate(`/${EMODULE_ROUTES.USERS}/${EMODULE_USER_ROUTES.USER}`, {
      relative: true,
    });
    // });
  };

  const onLogout = useCallback(async () => {
    removeKeySite();
    dispatchActions({ type: EUserType.LOGOUT, payload: {} }, userDispatch);
  }, [error, dispatch]);

  return {
    user,
    loading,
    onLogin,
    onLogout,
  };
};
