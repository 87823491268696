/** @format */

import { createSlice } from '@reduxjs/toolkit';
import { ELanguage, IActionReducerWithCallback, IAppState } from 'types';

const initialState = {
  loading: false,
  error: null,
  language: ELanguage.VI,
  isAuth: false,
  user: null,
} as IAppState;

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    actionRequest: state => {
      return {
        ...state,
        error: null,
        loading: true,
      };
    },
    getDataSuccess: (state, { payload }: { payload: any }) => {
      return {
        ...state,
        error: null,
        loading: false,
        ...payload,
      };
    },
    reset: () => initialState,
    requestDataStore: (state, { payload }: { payload: any }) => ({
      ...state,
      ...payload,
    }),
    requestDataWithcb: (state, { payload }: IActionReducerWithCallback) => {
      return payload?.callback?.(state, newState => {
        return { ...newState };
      });
    },
  },
});

export const { actionRequest, getDataSuccess, reset, requestDataStore, requestDataWithcb } =
  userSlice.actions;

export default userSlice.reducer;
