/** @format */

import React, { memo, useCallback, useEffect, useState } from 'react';
import { Col, message } from 'antd';
import './styles.scss';
import { Content, AddEdit, Info } from './components';
import { Modal, Text } from 'components';
import { EnumActionForm, EnumVariantModal } from 'utils';
import { usePromotion } from 'hooks';

const CenterManage = () => {
  const { onDeleteRecord } = usePromotion();
  const [actionRecord, setActionRecord] = useState<Record<'action' | 'record', any>>();
  const [isRefresh, setReFresh] = useState(false);

  const handleAction = useCallback(({ record, action: act }: { record?: any; action: string }) => {
    setActionRecord({ action: act, record });
  }, []);

  const handleClose = useCallback(() => {
    setActionRecord(undefined);
  }, []);

  const handleRefresh = useCallback(() => {
    setReFresh(s => !s);
  }, []);

  const handleConfirmDelRecord = useCallback(() => {
    onDeleteRecord(actionRecord?.record, res => {
      if (res.status === 200) {
        handleClose();

        handleRefresh();
      }
    });
  }, [actionRecord]);

  const handleActionHeaderClick = useCallback((action?: string) => {
    if (action === EnumActionForm.cancel) return handleClose();
    if (action === EnumActionForm.save) return message.info('Chưa có API');
    if (action === EnumActionForm.create) setActionRecord({ action, record: undefined });
  }, []);
  useEffect(() => {
    isRefresh && handleRefresh();
  }, [isRefresh]);
  return (
    <Col className='page-container apartment-page radius-4'>
      {actionRecord?.action === EnumActionForm.edit ||
      actionRecord?.action === EnumActionForm.create ? (
        <AddEdit
          record={actionRecord?.record}
          onRefresh={handleRefresh}
          onClose={handleClose}
          onActionClick={handleActionHeaderClick}
        />
      ) : (
        <Content onActionRecord={handleAction} isRefresh={isRefresh} />
      )}
      {actionRecord?.action === EnumActionForm.view && <Info open onClose={handleClose} />}
      {actionRecord?.action === EnumActionForm.delete && (
        <Modal
          open={true}
          title={'lbl_confirm'}
          useI18n
          variant={EnumVariantModal.confirm}
          buttonsProps={{
            save: {
              onClick: handleConfirmDelRecord,
            },
            cancel: {
              onClick: handleClose,
            },
          }}
          onCancel={handleClose}
        >
          <Text
            className='my-2'
            replace={{
              from: '{record}',
              to: actionRecord?.record?.name,
            }}
            useI18n
          >
            msg_confirm_delete_record
          </Text>
        </Modal>
      )}
    </Col>
  );
};

export default memo(CenterManage);
