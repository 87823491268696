/** @format */

import { Col, Row } from 'antd';
import { Text } from 'components';
import { memo } from 'react';

interface ICard {
  data: any[];
  subTitle?: string;
  title?: string;
}

function Card({ data, subTitle, title }: ICard) {
  return (
    <Col className='personal-infor p-2 mt-1'>
      {title && <Text className='color-dark font-weight-6 font-16 mb-2'>{title}</Text>}
      {subTitle && <Text className='color-dark font-weight-6 font-14 mb-2'>{subTitle}</Text>}
      <Col className='content'>
        {data?.map((record, idx) => (
          <Row
            key={record.title + idx}
            justify='space-between'
            wrap={false}
            className={`${idx < data?.length - 1 ? 'border-bottom' : ''} pb-2 pt-2`}
          >
            <Text className='nowrap'>{record?.title}</Text>
            <Text style={{ wordBreak: 'break-word', maxWidth: '50%' }}>{record?.value}</Text>
          </Row>
        ))}
      </Col>
    </Col>
  );
}
export default memo(Card);
