/** @format */

import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { baseActionRequest } from 'store/actions';
import { IDataStore, IPaginationClient, IUser } from 'types';
import { API_ENDPOINT, DataHelpers, KEY_CONTEXT } from 'utils';
import { userActions } from 'store/types';
import { getAttributesByKey } from 'store/selectors';
import { useKey } from './useKey';
import { message } from 'antd';
import { TFunction } from 'i18next';
import { useNotify } from './useNotify';

export function useUser() {
  const dispatch = useDispatch();
  const { getKey } = useKey();
  const { showMessageSuccess, showMessageError } = useNotify();
  const loading = useSelector(getAttributesByKey('user', 'loading'));
  const user =
    (useSelector(getAttributesByKey('user', 'user')) as IUser) ||
    (getKey(KEY_CONTEXT.USER) && JSON.parse(getKey(KEY_CONTEXT.USER) || '{}')) ||
    null;
  const profile = (useSelector(getAttributesByKey('user', 'profile')) as IDataStore<IUser>) || null;

  const records =
    (useSelector(getAttributesByKey('user', 'records')) as IDataStore<IUser[]>) || null;

  const getUserProfile = useCallback(() => {
    dispatch(
      baseActionRequest({
        payload: {
          dataKey: 'profile',
          isObject: true,
          endPoint: API_ENDPOINT.GET_PROFILE,
        },
        type: userActions.GET_BASE_ACTIONS,
      }),
    );
  }, [dispatch]);
  const onUpdateUserProfile = useCallback(
    async (payload: any, callback?: (res) => void) => {
      const { id, ...profileUser } = payload;
      const formSubmit = DataHelpers.parseObjectToFormData(profileUser);
      dispatch(
        baseActionRequest({
          payload: {
            formData: formSubmit,
            endPoint: API_ENDPOINT.USER.UPDATE_PROFILE + id,
            axiosConfig: {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            },
          },
          type: userActions.POST_BASE_ACTIONS,
          callback: result => callback?.(result),
        }),
      );
    },
    [dispatch],
  );
  const onGetRecords = useCallback(
    ({
      pagination,
    }: { pagination?: Partial<Pick<IPaginationClient, 'page' | 'pageSize'>> } = {}) => {
      dispatch(
        baseActionRequest({
          payload: {
            dataKey: 'records',
            endPoint: API_ENDPOINT.ADMIN.USER.ALL_USER,
            axiosConfig: {
              params: {
                limit: pagination?.pageSize,
                page: pagination?.page,
              },
            },
          },
          type: userActions.GET_BASE_ACTIONS,
        }),
      );
    },
    [dispatch],
  );

  const onAddUpdateRecord = useCallback(
    async (payload: any, callback?: (res) => void) => {
      const { id, ...profileUser } = payload;
      const formSubmit = DataHelpers.flattenObject(profileUser);
      // endPoint: !id ? API_ENDPOINT.ADMIN.USER.CREATE : API_ENDPOINT.ADMIN.USER.UPDATE + id,
      dispatch(
        baseActionRequest({
          payload: {
            formData: formSubmit,
            endPoint: !id ? API_ENDPOINT.ADMIN.USER.CREATE : API_ENDPOINT.ADMIN.USER.UPDATE + id,
            axiosConfig: {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            },
          },
          type: userActions.POST_BASE_ACTIONS,
          callback: result => callback?.(result),
        }),
      );
    },
    [dispatch],
  );
  const onDeleteRecord = useCallback(
    async (payload: any, callback?: (res) => void) => {
      const { id } = payload;
      if (!id) return;
      const endPoint = `${API_ENDPOINT.ADMIN.USER.REMOVE_BY_ID}`;
      dispatch(
        baseActionRequest({
          payload: {
            endPoint,
            axiosConfig: {
              params: {
                userId: id,
              },
            },
          },
          type: userActions.POST_BASE_ACTIONS,
          callback: result => {
            if (result?.status === 200) message.success('Thành công');
            else message.error('Thất bại');
            callback?.(result);
          },
        }),
      );
    },
    [dispatch],
  );
  const onAdminCreateUser = useCallback(
    async (
      payload: any,
      t: TFunction<'translation', undefined, 'translation'>,
      callback?: (res) => void,
    ) => {
      const { id = '', ...restForm } = payload;
      // const formData = DataHelpers.parseObjectToFormData(restForm);
      dispatch(
        baseActionRequest({
          payload: {
            formData: restForm,
            endPoint: !id ? API_ENDPOINT.ADMIN.USER.CREATE : API_ENDPOINT.ADMIN.USER.UPDATE + id,
            axiosConfig: {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            },
          },
          type: userActions.POST_BASE_ACTIONS,
          callback: result => {
            if (result?.status === 201 || result?.status === 200) {
              showMessageSuccess(t('msg_success01'));
              callback?.(result);
            } else {
              showMessageError('Thất bại');
            }
          },
        }),
      );
    },
    [dispatch],
  );
  return {
    loading,
    user,
    records,
    profile,

    getUserProfile,
    onUpdateUserProfile,
    onGetRecords,
    onAddUpdateRecord,
    onDeleteRecord,
    onAdminCreateUser,
  };
}
