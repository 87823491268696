/** @format */

import { EMODULE_ROUTES } from 'utils/enums';

export const menuSiders = [
  {
    key: EMODULE_ROUTES.USERS,
    title: 'lbl_manage_user',
    // icon: 'FriendOutline',
  },
  {
    key: EMODULE_ROUTES.RESTAURANT,
    title: 'lbl_manage_restaurant',
    // icon: 'FriendOutline',
  },
  {
    key: EMODULE_ROUTES.RESTAURANT_CATALOG,
    title: 'lbl_manage_restaurant_catalog',
    // icon: 'FriendOutline',
  },
  {
    key: EMODULE_ROUTES.NOTIFICATION,
    title: 'lbl_notification',
    // icon: 'FriendOutline',
  },
  {
    key: EMODULE_ROUTES.PROMOTION,
    title: 'lbl_promotion',
    // icon: 'FriendOutline',
  },
  {
    key: EMODULE_ROUTES.CUSTOMER_CATALOG,
    title: 'lbl_customer_catalog',
    // icon: 'FriendOutline',
  },
  {
    key: EMODULE_ROUTES.SUPPORT,
    title: 'lbl_support',
    // icon: 'FriendOutline',
  },
];
