/**
 * @format
 * @description the hook to show notification by toast message
 */

import { message } from 'antd';
import { ArgsProps } from 'antd/es/message';
import { TypeOpen } from 'antd/es/message/interface';
import { Key, useCallback } from 'react';
import { useDispatch } from 'react-redux';

export const useNotify = () => {
  const [messageApi] = message.useMessage();
  const dispatch = useDispatch();
  const showMessage = useCallback(
    (props: ArgsProps) => {
      messageApi.open(props);
    },
    [dispatch],
  );

  const showMessageInfo: TypeOpen = useCallback(
    (content, duration, onClose) => messageApi.info(content, duration, onClose),
    [showMessage],
  );

  const showMessageSuccess: TypeOpen = useCallback(
    (content, duration, onClose) => message.success(content, duration, onClose),
    [showMessage, message],
  );

  const showMessageError: TypeOpen = useCallback(
    (content, duration, onClose) => messageApi.error(content, duration, onClose),
    [showMessage],
  );

  const showMessageWarning: TypeOpen = useCallback(
    (content, duration, onClose) => messageApi.warning(content, duration, onClose),
    [showMessage],
  );
  const hide = useCallback((key: Key) => messageApi.destroy(key), []);
  return {
    showMessage,
    showMessageInfo,
    showMessageWarning,
    showMessageError,
    showMessageSuccess,
    hide,
  };
};
