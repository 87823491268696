/** @format */

import React, { forwardRef, memo, useMemo } from 'react';
import { Text } from 'components';
import { IBaseInput, IInputDatetime, IInputNumber, IInputCombobox, IInputUpload } from 'types';
import { DataHelpers, EnumInputVariant, EnumValueType } from 'utils';
import {
  BaseInput,
  DatetimeInput,
  NumberInput,
  SearchInput,
  SelectInput,
  UploadInput,
} from './components';
import { useTranslation } from 'react-i18next';
import { Form, Input, InputRef, Row } from 'antd';
import './styles.scss';
const InputCus = forwardRef<
  InputRef | HTMLInputElement | any,
  IBaseInput | IInputNumber | IInputDatetime | IInputUpload | IInputCombobox
>(function InputWithRef(
  {
    variant = EnumInputVariant.base,
    containerClass = '',
    title,
    useI18nTitle = false,
    titleClassname = '',
    titleProps,
    placeholder = '',
    useI18nPlaceholder,
    required,
    className = '',
    allowClear = true,
    isForm = true,
    formItemProps,
    showTitle = variant !== EnumInputVariant.upload,
    ...props
  }: any,
  ref,
) {
  const { t } = useTranslation();

  const components = {
    [EnumInputVariant.base]: BaseInput,
    [EnumInputVariant.number]: NumberInput,
    [EnumInputVariant.datetime]: DatetimeInput,
    [EnumInputVariant.search]: SearchInput,
    [EnumInputVariant.upload]: UploadInput,
    [EnumInputVariant.combobox]: SelectInput,
    [EnumInputVariant.password]: Input.Password,
  };
  const palceholderTranslate = useMemo(
    () => (useI18nPlaceholder ? t(placeholder) : placeholder),
    [placeholder, useI18nPlaceholder],
  );
  const inputLabel = useMemo(() => {
    return (
      title && (
        <Row align={'middle'} {...titleProps} className={`mb-1 wrap-title ${titleClassname}`}>
          <Text useI18n={useI18nTitle}>{title}</Text>
          {required && <Text className='icon-required ml-1'>*</Text>}
        </Row>
      )
    );
  }, [title, titleProps, useI18nTitle, required, titleClassname]);
  const formItemRules = useMemo(() => {
    if (DataHelpers.getDataType(formItemProps?.rules) === EnumValueType.Array) {
      return formItemProps?.rules?.map((rule: any) => ({
        ...rule,
        required: rule.required || required,
        message: (rule.message && <Text useI18n={rule?.useI18n}>{rule.message}</Text>) || undefined,
      }));
    }
    return formItemProps?.rules;
  }, [formItemProps?.rules, required, props?.name]);
  const ComponentInput = components[variant] || BaseInput;
  return (
    <div className={`mt-1 f-width ${containerClass}`}>
      {showTitle && inputLabel}
      {isForm ? (
        <Form.Item
          {...formItemProps}
          name={formItemProps?.name || props?.name}
          rules={formItemRules}
        >
          <ComponentInput
            ref={ref as any}
            {...(props as any)}
            className={`${className} input-default`}
            required={required}
            placeholder={palceholderTranslate}
            allowClear={allowClear}
            title={!showTitle ? inputLabel : undefined}
          />
        </Form.Item>
      ) : (
        <ComponentInput
          ref={ref as any}
          {...(props as any)}
          className={`${className} input-default`}
          required={required}
          placeholder={palceholderTranslate}
          allowClear={allowClear}
          title={!showTitle ? inputLabel : undefined}
        />
      )}
    </div>
  );
});

export default memo(InputCus);
