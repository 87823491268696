/** @format */
import { ColorSystem } from '@mui/material';

export enum ILayoutActionsType {
  TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR',
  UPDATE_INFO_PAGE = 'UPDATE_INFO_PAGE',
  CHANGE_THEME = 'CHANGE_THEME',
  FOLDER_FETCH = 'FOLDER_FETCH',
  FILTER_KEY = 'FILTER_KEY',
  CHOOSE_FILE = 'CHOOSE_FILE',
  SELECTE_MULTI_FILE = 'SELECTE_MULTI_FILE',
  MODE_FORM = 'MODE_FORM',
  UPDATE_PARAMS = 'UPDATE_PARAMS',
  MODE_FORM_LATEST = 'MODE_FORM_LATEST',
  DATA_POPUP = 'DATA_POPUP',
  REMOVE_FILE = 'REMOVE_FILE',
}
export type TViewForm = 'GridView' | 'FormView';
export enum TViewFolder {
  'ListIcon' = 'ListIcon',
  'GridIcon' = 'GridIcon',
}
export interface IMenuBar {
  children: any[]; // Array(6) [ {…}, {…}, {…}, … ]
  expanded: boolean; // false
  extendedField?: string; // null
  grid?: string; // null
  iconcls: string; // "DM"
  id?: string; // null
  isdefault: boolean; // false
  isshow: string; // "Y"
  leaf: boolean; // false
  mappingField?: string; // null
  shortcmd: string; // "DM"
  store?: string; // null
  text: string; // "Documents"
  xclass?: string; // null
}

export interface CommonColors extends ColorSystem {
  black: string;
  white: string;
  border: string;
  bg_wrap: string;
}
