/** @format */

import { EnumPrefixBaseActionType } from 'utils';

export const promotion = {
  GET_BASE_ACTIONS: `promotion/${EnumPrefixBaseActionType.GET_BASE_ACTIONS}`,
  POST_BASE_ACTIONS: `promotion/${EnumPrefixBaseActionType.POST_BASE_ACTIONS}`,
  DELETE_BASE_ACTIONS: `promotion/${EnumPrefixBaseActionType.DELETE_BASE_ACTIONS}`,
  PUT_BASE_ACTIONS: `promotion/${EnumPrefixBaseActionType.PUT_BASE_ACTIONS}`,
  PATCH_BASE_ACTIONS: `promotion/${EnumPrefixBaseActionType.PATCH_BASE_ACTIONS}`,
};
