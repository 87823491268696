/** @format */

import React, { FC, memo, useCallback, useEffect, useState } from 'react';
import { Row } from 'antd';
import { DataTable } from 'components';
import { EnumActionForm, EnumTableAction, columnsRestaurantCatalog } from 'utils';
import { IPageContentBase, IPaginationClient } from 'types';

import { Filters } from './Filter';
import { useRestaurantCatalog } from 'hooks';

const Content: FC<IPageContentBase> = ({ onActionRecord, isRefresh }) => {
  const { records, onGetRecords } = useRestaurantCatalog();
  const [pagination, setPagination] = useState<Partial<IPaginationClient>>({
    page: 1,
    pageSize: 10,
  });
  const handleHeaderAction = useCallback(
    act => {
      switch (act) {
        case EnumActionForm.create:
          onActionRecord?.({ action: act, record: undefined });
          break;
        default:
          break;
      }
    },
    [onActionRecord],
  );
  const handlePageChange = useCallback((page, pageSize) => setPagination({ page, pageSize }), []);

  useEffect(() => {
    pagination && onGetRecords({ pagination });
  }, [pagination]);
  useEffect(() => {
    isRefresh && onGetRecords({ pagination });
  }, [isRefresh]);
  return (
    <Row className='d-flex f-width mt-3 mb-3'>
      <Filters onActionClick={handleHeaderAction} />
      <DataTable
        columnDefines={columnsRestaurantCatalog}
        onTableActionClick={onActionRecord}
        actions={[EnumTableAction.delete, EnumTableAction.edit]}
        dataSource={records?.data}
        pagination={{
          current: pagination?.page,
          pageSize: pagination?.pageSize,
          total: records?.pagination?.totalCount,
          onChange: handlePageChange,
        }}
      />
    </Row>
  );
};

export default memo(Content);
