/** @format */

import React, { FC, memo } from 'react';
import { ICheckbox } from 'types';
import './styles.scss';
import { Checkbox } from 'antd';
import { Text } from 'components';

const CheckboxCus: FC<ICheckbox> = ({ className = '', title, useI18n, titleProps, ...props }) => {
  return (
    <Checkbox {...props} className={`${className} checkbox-default`}>
      <Text
        {...titleProps}
        useI18n={useI18n}
        className={`checkbox-title ${titleProps?.className || ''}`}
      >
        {title}
      </Text>
    </Checkbox>
  );
};

export default memo(CheckboxCus);
