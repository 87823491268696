/** @format */

import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { baseActionRequest } from 'store/actions';
import { IDataStore, IPaginationClient } from 'types';
import { store_lookupActions } from 'store/types';
import { getAttributesByKey } from 'store/selectors';
import { AxiosRequestConfig } from 'axios';

export function useStoreLookup(key = '') {
  const dispatch = useDispatch();
  const isLoading = useSelector(getAttributesByKey('store_lookup', 'isLoading'));
  const records =
    (useSelector(getAttributesByKey('store_lookup', `records-${key}`)) as IDataStore<any[]>) ||
    null;

  const onGetRecords = useCallback(
    ({
      pagination,
      endPoint,
      axiosConfig,
      type = store_lookupActions.GET_BASE_ACTIONS,
    }: {
      type?: string;
      pagination?: Partial<Pick<IPaginationClient, 'page' | 'pageSize'>>;
      endPoint: string;
      axiosConfig?: AxiosRequestConfig<string>;
    }) => {
      dispatch(
        baseActionRequest({
          payload: {
            dataKey: `records-${key}`,
            endPoint,
            axiosConfig: {
              ...axiosConfig,
              params: {
                limit: pagination?.pageSize,
                page: pagination?.page,
              },
            },
          },
          type,
        }),
      );
    },
    [dispatch],
  );

  return {
    isLoading,
    records,

    onGetRecords,
  };
}
