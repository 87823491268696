/** @format */

import { IColumnsTableDefined, IBaseInput, IInputDatetime } from 'types';
import { EnumInputVariant } from 'utils/enums';
import { TimeHelpers } from 'utils/helpers';

export const columnsRestaurant: Partial<IColumnsTableDefined>[] = [
  {
    title: 'RESTAURANT_name',
    dataIndex: 'name',
    key: 'name',
    fixed: 'left',
  },
  {
    title: 'RESTAURANT_address',
    dataIndex: 'address',
    key: 'address',
  },
  {
    title: 'RESTAURANT_managers',
    dataIndex: 'managers',
    key: 'managers',
    format(value) {
      return value
        .map(item => item.email)
        ?.filter(i => i)
        .join(', ');
    },
  },
  {
    title: 'RESTAURANT_open_time',
    dataIndex: 'open_time',
    key: 'open_time',
    format(value) {
      return `${(value.time && value.time + ',') || ''} ${value?.day || ''}`;
    },
  },
  {
    title: 'RESTAURANT_stop_time',
    dataIndex: 'stop_time',
    key: 'stop_time',
    format(value) {
      return TimeHelpers.formatDate(value, 'X');
    },
  },
  {
    title: 'RESTAURANT_status',
    dataIndex: 'status',
    key: 'status',
    format(value) {
      return value ? 'RESTAURANT_status_active' : 'RESTAURANT_status_inactive';
    },
  },
];

export const inputFormRestaurant: (IBaseInput | IInputDatetime)[] = [
  {
    name: 'name',
    title: 'RESTAURANT_name',
    useI18nTitle: true,
    placeholder: 'RESTAURANT_name',
    useI18nPlaceholder: true,
    required: true,
    formItemProps: {
      name: 'name',
      rules: [{ required: true, message: 'RESTAURANT_name_required', useI18n: true }],
    },
  },
  {
    name: 'address',
    title: 'RESTAURANT_address',
    useI18nTitle: true,
    placeholder: 'RESTAURANT_address',
    useI18nPlaceholder: true,
  },
  {
    name: 'location.lat',
    title: 'RESTAURANT_location.lat',
    useI18nTitle: true,
    placeholder: 'RESTAURANT_location.lat',
    useI18nPlaceholder: true,
    variant: EnumInputVariant.number,
    formItemProps: {
      name: ['location', 'lat'],
      // rules: [{ type: 'number', message: 'Định dạng số' }],
    },
  },
  {
    name: 'location.long',
    title: 'RESTAURANT_location.long',
    useI18nTitle: true,
    placeholder: 'RESTAURANT_location.long',
    useI18nPlaceholder: true,
    variant: EnumInputVariant.number,
    formItemProps: {
      name: ['location', 'long'],
      // rules: [{ type: 'number', message: 'Định dạng số' }],
    },
  },

  {
    name: 'phone',
    title: 'RESTAURANT_phone',
    useI18nTitle: true,
    placeholder: 'RESTAURANT_phone',
    useI18nPlaceholder: true,
    formItemProps: {
      name: ['contact', 'phone'],
    },
  },
  {
    name: 'openTime.day',
    title: 'RESTAURANT_openTime.day',
    useI18nTitle: true,
    placeholder: 'RESTAURANT_openTime.day',
    useI18nPlaceholder: true,
    formItemProps: {
      name: ['openTime', 'day'],
    },
  },
  {
    name: 'openTime.time',
    title: 'RESTAURANT_openTime.time',
    useI18nTitle: true,
    placeholder: 'RESTAURANT_openTime.time',
    useI18nPlaceholder: true,
    formItemProps: {
      name: ['openTime', 'time'],
    },
  },
  {
    name: 'bankInformation.bankName',
    title: 'RESTAURANT_bankInformation.bankName',
    useI18nTitle: true,
    placeholder: 'RESTAURANT_bankInformation.bankName',
    useI18nPlaceholder: true,
    formItemProps: {
      name: ['bankInformation', 'bankName'],
    },
  },
  {
    name: 'bankInformation.accountName',
    title: 'RESTAURANT_bankInformation.accountName',
    useI18nTitle: true,
    placeholder: 'RESTAURANT_bankInformation.accountName',
    useI18nPlaceholder: true,
    formItemProps: {
      name: ['bankInformation', 'accountName'],
    },
  },
  {
    name: 'bankInformation.accountNumber',
    title: 'RESTAURANT_bankInformation.accountNumber',
    useI18nTitle: true,
    placeholder: 'RESTAURANT_bankInformation.accountNumber',
    useI18nPlaceholder: true,
    formItemProps: {
      name: ['bankInformation', 'accountNumber'],
    },
  },
];

export const inputApartmentFilters = columnsRestaurant.map((item): IBaseInput => {
  return {
    key: item.key,
    title: item.title,
    useI18nTitle: true,
    placeholder: item.title,
    useI18nPlaceholder: true,
  };
});
export const dropdownFilterApartments = columnsRestaurant.map(item => {
  return {
    key: item.key,
    title: item.title,
    useI18nTitle: true,
  };
});
