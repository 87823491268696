/** @format */

import { IColumnsTableDefined, IBaseInput, IInputDatetime } from 'types';
import { EnumInputVariant } from 'utils/enums';

export const columnsRestaurantCatalog: Partial<IColumnsTableDefined>[] = [
  {
    title: 'lbl_catalog_name',
    dataIndex: 'name',
    key: 'name',
    fixed: 'left',
  },
  {
    title: 'lbl_type',
    dataIndex: 'type',
    key: 'type',
  },
  {
    title: 'lbl_count',
    dataIndex: 'count',
    key: 'count',
  },
  {
    title: 'lbl_queue_number',
    dataIndex: 'queue_number',
    key: 'queue_number',
  },
  {
    title: 'lbl_icon',
    dataIndex: 'icon',
    key: 'icon',
  },
];

export const inputFormRestaurantCatalog: (IBaseInput | IInputDatetime)[] = [
  {
    name: 'name',
    title: 'lbl_name',
    useI18nTitle: true,
    placeholder: 'lbl_name',
    useI18nPlaceholder: true,
    required: true,
    formItemProps: {
      rules: [{ required: true, message: 'msg_name_required', useI18n: true }],
    },
  },
  {
    name: 'queue_number',
    title: 'lbl_queue_number',
    useI18nTitle: true,
    placeholder: 'lbl_queue_number',
    useI18nPlaceholder: true,
    formItemProps: {
      rules: [
        {
          type: 'number',
          message: 'msg_must_is_number',
          useI18n: true,
        },
      ],
    },
    variant: EnumInputVariant.number,
  },
  {
    name: 'count',
    title: 'lbl_count',
    useI18nTitle: true,
    placeholder: 'lbl_count',
    useI18nPlaceholder: true,
    formItemProps: {
      rules: [
        {
          type: 'number',
          message: 'msg_must_is_number',
          useI18n: true,
        },
      ],
    },
    variant: EnumInputVariant.number,
  },
  {
    name: 'type',
    title: 'lbl_type',
    useI18nTitle: true,
    placeholder: 'lbl_type',
    useI18nPlaceholder: true,
  },
];

export const inputRestaurantCatalogFilters = columnsRestaurantCatalog.map((item): IBaseInput => {
  return {
    key: item.key,
    title: item.title,
    useI18nTitle: true,
    placeholder: item.title,
    useI18nPlaceholder: true,
  };
});
export const dropdownFilterRestaurantCatalogs = columnsRestaurantCatalog.map(item => {
  return {
    key: item.key,
    title: item.title,
    useI18nTitle: true,
  };
});
