/** @format */

import React, { forwardRef, memo } from 'react';
import { Input, InputRef } from 'antd';
import { IBaseInput } from 'types';
const BaseInput = forwardRef<InputRef, IBaseInput>(function InputWithRef({ ...props }, ref) {
  return <Input ref={ref} {...props} />;
});

export default memo(BaseInput);
