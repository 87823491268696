/** @format */

import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { baseActionRequest } from 'store/actions';
import { IContextState, ILayoutActionsType, IPayloadImageCDN } from 'types';
import { API_ENDPOINT } from 'utils';
import * as reducers from 'store/reducers';
import { dispatchLayoutActions, useLayoutDispatch, useLayoutState } from 'context/LayoutContext';
import { useKey } from 'hooks';

export function useCommon() {
  const dispatch = useDispatch();
  const layoutDispatch = useLayoutDispatch();
  const { setStorage } = useKey();
  const layoutState = useLayoutState() || {};
  const onUpdateDataStore = useCallback(
    ({ store, payload }: { store: string; payload: any }) => {
      const { requestDataStore } = reducers?.[store] || {};
      requestDataStore && dispatch(requestDataStore?.(payload));
    },
    [dispatch],
  );
  const onUpdateLayoutState = useCallback(
    (payload: Partial<IContextState>) => {
      dispatchLayoutActions({ type: ILayoutActionsType.UPDATE_PARAMS, payload }, layoutDispatch);
    },
    [dispatchLayoutActions, layoutDispatch],
  );
  const onUpdateDataStorage = useCallback(
    ({ key, record, parseString = true }: { key: string; record: any; parseString?: boolean }) => {
      setStorage({ key, record, parseString });
    },
    [],
  );

  const onGetImageCDN = useCallback(
    ({ path, format = 'webp', width, height }: IPayloadImageCDN, callback?: (File) => void) => {
      return dispatch(
        baseActionRequest({
          payload: {
            endPoint: API_ENDPOINT.IMAGE_CDN,
            formData: { path, format, width, height },
            axiosConfig: {
              responseType: 'blob',
            },
          },
          callback: res => {
            const base64 = URL.createObjectURL(new Blob([res] as any, { type: 'image/webp' }));
            callback?.(base64);
          },
        }),
      );
    },
    [dispatch],
  );

  return {
    ...layoutState,
    /* ----change data CONTEXT---- */
    onUpdateLayoutState,
    /* ----change data STORE---- */
    onUpdateDataStore,
    /* ----change data STORAGE---- */
    onUpdateDataStorage,

    onGetImageCDN,
  };
}
