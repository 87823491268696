/** @format */

import type { IInputDatetime } from 'types';
import React, { forwardRef, memo, useCallback, useEffect, useState } from 'react';
import { DatePicker } from 'antd';
import 'dayjs/locale/vi';
import localeDefault from 'antd/es/date-picker/locale/vi_VN';
import dayjs, { Dayjs } from 'dayjs';
import { EnumInputDatimeParseTo } from 'utils';

const DateTime = forwardRef<HTMLInputElement, IInputDatetime>(function DateTime(
  {
    format = 'DD-MM-YYYY',
    value,
    parseTo = EnumInputDatimeParseTo.Unit,
    locale = localeDefault,
    onChange,
    ...props
  },
  ref,
) {
  const [current, setValueCurrent] = useState<Dayjs | null>(null);
  const handleChange = useCallback(
    day => {
      const val = day
        ? parseTo === EnumInputDatimeParseTo.Unit
          ? dayjs(day).unix()
          : dayjs(day).toISOString()
        : '';
      onChange?.(val as any, day as string);
      setValueCurrent(day);
    },
    [onChange, parseTo],
  );
  useEffect(() => {
    if (value && (dayjs(value).isValid() || dayjs.unix(value as any).isValid())) {
      const valueParseTo =
        parseTo === EnumInputDatimeParseTo.Unit ? dayjs.unix(value as any) : dayjs(value);
      setValueCurrent(valueParseTo);
    } else setValueCurrent(null);
  }, [value]);
  return (
    <DatePicker
      ref={ref as any}
      {...props}
      value={current}
      onChange={handleChange}
      format={format}
      locale={locale}
    />
  );
});

export default memo(DateTime);
