/**
 * @format
 * @description the interface of user entity
 * @export
 * @interface IUser
 */

export enum EUserType {
  LOGIN = 'LOGIN',
  LOGOUT = 'LOGOUT',
  CHANGE_LANGUAGE = 'CHANGE_LANGUAGE',
  CHANGE_PASSWORD = 'CHANGE_PASSWORD',
  UPDATE_AVATAR = 'UPDATE_AVATAR',
  FORGOT_PASSWORD = 'FORGOT_PASSWORD',
  FORGOT_PASSWORD_SCODE = 'FORGOT_PASSWORD_SCODE',
  FORGOT_PASSWORD_SPASSWORD = 'FORGOT_PASSWORD_SPASSWORD',
}

export interface IDentityCard {
  front_image;
  back_image;
}
export interface IUser {
  account_bank: string;
  address: string;
  avatar: string;
  balance: number;
  balance_available: number;
  birthday: string;
  createdAt: string;
  email: string;
  full_name: string;
  id: string;
  identification: IDentityCard;
  license: IDentityCard;
  payment_settlement: string;
  phone_number: string;
  rating_point: number;
  role: string;
  unread_notifications: number;
  updatedAt: string;
  user_name: string;
}

export interface IParamsRequest {
  action: string;
  method: string;
  data: any;
  type: string;
  tid: number;
}
export interface IParamsDelLink {
  id?: string;
  autonum: string;
  linkto: string;
}

export interface IParamsDownLink {
  documentcode: string;
  linkto: string;
}

export type IRSResult = Record<string, any>;

export interface IResponse {
  data: IRSResult[];
  status: number;
}

export interface IUserAction {
  type: string;
  user: IUser;
}

/**
 * @description the interface of user reducer entity in redux
 * @export
 * @interface IUserState
 */
export interface IUserState {
  loading: boolean;
  error: string | null;
  listNoti: [];
}

/**
 * @description the interface of push notification data
 * @export
 * @interface PushNotifcation
 */
export interface PushNotifcation {
  id: string;
  token: string;
  brand?: string;
  osName?: string;
  modelId?: string;
  osVersion?: string;
  modelName?: string;
  deviceName?: string;
  manufacturer?: string;
}
export interface IFormConfirmOTP {
  code: string;
  confirmKey: string;
  confirmCode: string;
  secKey?: string;
  route?: string;
}

export interface IFormResetPassword {
  password: string;
  secKey: string;
  confirmCode: string;
  route?: string;
  newPassword?: string;
  confirmPassword?: string;
}
export interface IFormImgData {
  documentcode?: string; // documentcode
  autonum?: string; //autonum
  filename?: string; // name
  subject?: string; // subject
  category?: string; // category
  data?: string; // data
  linkto: string;
  referencekey1: string; // user.prospect
  referencekey2?: string;
  comments?: string;
}
export interface IFormSkipLimit {
  start: number;
  limit: number;
}
