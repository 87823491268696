/** @format */

import React, { memo, useCallback, useEffect, useState } from 'react';
import { Col, message } from 'antd';
import { AddEdit, Confirm, ContentUser, Header } from './components';
import './styles.scss';
import { EnumActionForm } from 'utils';
const User = () => {
  const [actionRecord, setActionRecord] = useState<Record<'action' | 'record', any>>();
  const [isRefresh, setReFresh] = useState(false);

  const handleAction = useCallback(({ record, action: act }: { record?: any; action: string }) => {
    setActionRecord({ action: act, record });
  }, []);

  const handleClose = useCallback(() => {
    setActionRecord(undefined);
  }, []);

  const handleRefresh = useCallback(() => {
    setReFresh(s => !s);
  }, []);

  const handleActionHeaderClick = useCallback((action?: string) => {
    if (action === EnumActionForm.cancel) return handleClose();
    if (action === EnumActionForm.save) return message.info('Chưa có API');
    if (action === EnumActionForm.create) setActionRecord({ action, record: undefined });
  }, []);

  /* Reset  */
  useEffect(() => {
    isRefresh && handleRefresh();
  }, [isRefresh]);

  const popupComponents = {
    [EnumActionForm.delete]: Confirm,
  };
  const ComponentModal = popupComponents?.[actionRecord?.action] || false;
  return (
    <Col className='page-container resident-page radius-4'>
      {actionRecord?.action === EnumActionForm.edit ||
      actionRecord?.action === EnumActionForm.create ? (
        <AddEdit
          record={actionRecord?.record}
          onRefresh={handleRefresh}
          onClose={handleClose}
          onActionClick={handleActionHeaderClick}
        />
      ) : (
        <>
          <Header onActionClick={handleActionHeaderClick} />
          <ContentUser onActionRecord={handleAction} isRefresh={isRefresh} />
        </>
      )}
      {ComponentModal && (
        <ComponentModal
          open={true}
          onClose={handleClose}
          onRefresh={handleRefresh}
          record={actionRecord?.record}
          action={actionRecord?.action}
          onActionRecord={handleAction}
        />
      )}
    </Col>
  );
};

export default memo(User);
