/** @format */

import { Row, Col } from 'antd';
import { Text, Button } from 'components';
import { useRestaurant } from 'hooks';
import React, { FC, memo, useCallback } from 'react';
import { EnumActionForm, EnumButtonVariant } from 'utils';
interface IHeaderApartmentEdit {
  onActionClick?: (action: EnumActionForm) => void;
}
const Header: FC<IHeaderApartmentEdit> = ({ onActionClick }) => {
  const { records } = useRestaurant();
  const handleActionClick = useCallback(action => () => onActionClick?.(action), [onActionClick]);
  return (
    <Row className='page-header' justify={'space-between'} align={'middle'}>
      <Col>
        <Text useI18n className='header-title'>
          lbl_manage_restaurant
        </Text>
        <Text
          useI18n
          className='font-12'
          replace={{ from: '{value}', to: `${records?.pagination?.totalCount || 0}` }}
        >
          lbl_total_count
        </Text>
      </Col>
      <Col>
        <Button
          variant={EnumButtonVariant.filter}
          title='txt.filter'
          useI18n
          className='btn-filter'
          onClick={handleActionClick(EnumActionForm.filter)}
        />
        <Button
          title='txt.file_export'
          className='ml-2'
          useI18n
          variant={EnumButtonVariant.export}
          onClick={handleActionClick(EnumActionForm.export)}
        />
        <Button
          title='txt.file_import'
          useI18n
          variant={EnumButtonVariant.import}
          onClick={handleActionClick(EnumActionForm.import)}
          className='ml-2 mr-2'
        />
        <Button
          title='txt.add'
          useI18n
          variant={EnumButtonVariant.add}
          onClick={handleActionClick(EnumActionForm.create)}
        />
      </Col>
    </Row>
  );
};

export default memo(Header);
