/** @format */

import { Text } from 'components';
import React, { FC, memo, useMemo } from 'react';
import { IModal, IModalRight } from 'types';
import { EnumVariantModal } from 'utils';
import { Base, Right, Confirm } from './components';

const Modal: FC<IModalRight | IModal> = ({
  variant = EnumVariantModal.base,
  destroyOnClose = true,
  footer = false,
  title = '',
  useI18n,
  titleProps,
  ...props
}) => {
  const modalTitle = useMemo(
    () => (
      <Text useI18n={useI18n} {...titleProps}>
        {title as string}
      </Text>
    ),
    [title, useI18n, JSON.stringify(titleProps)],
  );

  const component = {
    [EnumVariantModal.base]: Base,
    [EnumVariantModal.right]: Right,
    [EnumVariantModal.confirm]: Confirm,
  };
  const ComponentModal = component[variant] || Base;
  return (
    <ComponentModal title={modalTitle} {...props} destroyOnClose={destroyOnClose} footer={footer} />
  );
};

export default memo(Modal);
