/** @format */

import React, { forwardRef, memo, useCallback, useEffect, useId, useMemo, useRef } from 'react';
import { Select } from 'antd';
import { IInputCombobox, IOptionsConfig } from 'types';
import { BaseSelectRef } from 'rc-select';
import { Text } from 'components';
import { useStoreLookup } from 'hooks';
const SelectInput = forwardRef<BaseSelectRef, IInputCombobox>(function InputWithRef(
  { options, optionsDefine, optionsConfig, ...props },
  ref,
) {
  const currentId = useRef(useId()).current;
  const { records, onGetRecords } = useStoreLookup(currentId);
  const optionsCustom = useMemo(() => {
    if (options) return options;
    if (optionsDefine)
      return optionsDefine.map(opt => ({
        value: opt.value,
        label: (
          <Text useI18n={opt.useI18n} {...opt.titleProps}>
            {opt.title}
          </Text>
        ),
      }));

    return (
      records?.data?.map(record => ({
        value: record?.[optionsConfig?.valueKey || ''],
        label: record?.[optionsConfig?.titleKey || ''],
      })) || []
    );
  }, [optionsDefine, options, optionsConfig, records?.data]);

  const handleAsyncOptionsLoad = useCallback(() => {
    const { loader, ...config } = (optionsConfig || {}) as IOptionsConfig;
    if (loader) {
      loader?.();
      return;
    }
    if (!config) return;
    onGetRecords({ endPoint: config.endPoint, axiosConfig: { params: config.params } });
  }, [optionsConfig]);

  useEffect(() => {
    handleAsyncOptionsLoad();
  }, [optionsConfig]);

  return <Select ref={ref} {...props} options={optionsCustom} />;
});

export default memo(SelectInput);

/* Example ASYNC */

{
  /* <Input
variant={EnumInputVariant.combobox}
optionsConfig={{
  endPoint: API_ENDPOINT.APARTMENT.ALL,
  valueKey: 'id',
  titleKey: 'apartment_id',
}}
defaultValue={'35a95841-c9c0-46fa-bb8a-edf144e0ce57'}
/> */
}
