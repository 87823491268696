/** @format */

import React, { FC, memo } from 'react';
import './styles.scss';
import { Modal } from 'components';
import { EnumButtonVariant, EnumVariantModal } from 'utils';
import Icons from 'assets/icons';
import { Card } from './Card';

export interface IResidenInfo {
  open?: boolean;
  onClose?: () => void;
}

const ResidentInfo: FC<IResidenInfo> = ({ open, onClose }) => {
  return (
    <Modal
      open={open}
      variant={EnumVariantModal.right}
      onCancel={onClose}
      header={{
        title: 'lbl_apartment_code',
        titleProps: {
          useI18n: true,
          replace: {
            from: '{0}',
            to: 'B11.05',
          },
        },
        btnAction: 'btn_edit',
        btnProps: {
          useI18n: true,
          variant: EnumButtonVariant.base,
          icon: <Icons.Edit />,
        },
        icon: (<Icons.Home />) as any,
      }}
    >
      <Card data={fakeData_01} /* title='Thông tin cá nhân' */ />
      <Card
        data={fakeData_02}
        title='Thành viên trong căn hộ'
        subTitle='1. Đoàn Nguyễn Ngọc An - Người thuê'
      />
    </Modal>
  );
};

export default memo(ResidentInfo);

const fakeData_01 = [
  { title: 'Chung cư', value: 'PetroVietnam Lanmark' },
  { title: 'Địa chỉ', value: '69 Mai Chí Thọ, An Phú, TP Thủ Đức, TPHCM' },
  { title: 'Vị trí Căn hộ', value: 'Block B - Tầng 11' },
  { title: 'Loại phòng', value: 'Căn hộ studio' },
  { title: 'Mã Căn hộ', value: 'B11.05' },
  { title: 'Diện tích', value: '60m2' },
  { title: 'Hợp đồng', value: 'Hợp đồng cho thuê' },
  { title: 'Các loại dịch vụ', value: 'Gửi xe (3 chiếc) / Gym' },
  { title: 'Số cư dân hiện tại', value: '3 người' },
];
const fakeData_02 = [
  { title: 'Họ và tên', value: 'Đoàn Nguyễn Ngọc An' },
  { title: 'Số điện thoại', value: '0909611244' },
  { title: 'Email', value: 'Ngocan123@gmail.com' },
  { title: 'Số CMND / CCCD', value: '026073167' },
  { title: 'Ngày sinh', value: '06/12/1998' },
  { title: 'Giới tính', value: 'Nữ' },
  { title: 'Thông tin xe', value: 'Vision - A1.08417' },
];
