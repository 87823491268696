/** @format */

import React, { FC, isValidElement, memo } from 'react';
import { Modal as AntdModal, Col, Row } from 'antd';
import { IModalRight } from 'types';
import './styles.scss';
import { Button, Text } from 'components';

// const RightModal = forwardRef<HTMLDivElement, IModalRight>(function ModalCus({
const RightModal: FC<IModalRight> = ({
  className = '',
  children,
  closable = false,
  header,
  ...props
}) => {
  return (
    <AntdModal {...props} closable={closable} className={`f-height modal-right ${className}`}>
      <Row className='wrap-header mb-4' justify={'space-between'}>
        <Row>
          {!!header?.icon && <Col className='wrap-icon'>{header?.icon as any}</Col>}
          <Col className='wrap-title ml-3'>
            {!!header?.title && (
              <Text className='title-default' {...header?.titleProps}>
                {header?.title as string}
              </Text>
            )}
          </Col>
        </Row>
        <Col className='btn-right'>
          {isValidElement(header?.btnAction)
            ? header?.btnAction
            : !!header?.btnAction && (
                <Button
                  {...header?.btnProps}
                  className={`header-btn-default ${header?.btnProps?.className || ''}`}
                  title={(header?.btnAction || '') as string}
                  titleProps={{
                    ...header?.btnProps?.titleProps,
                    className: `${header?.btnProps?.titleProps?.className || ''} font-12`,
                  }}
                />
              )}
        </Col>
      </Row>
      <div className='modal-content'>{children}</div>
    </AntdModal>
  );
};

export default memo(RightModal);
