/** @format */

import React, { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Menu, Popover } from 'antd';
import { DataTable } from 'components';
import { columnsUsers, EnumActionForm, TableHelpers } from 'utils';
import { useUser } from 'hooks';
import { MoreOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { IPageContentBase, IPaginationClient } from 'types';
import './styles.scss';
import Icons from 'assets/icons';

const Content: FC<IPageContentBase> = ({ onActionRecord, isRefresh }) => {
  const { records, onGetRecords } = useUser();
  const [pagination, setPagination] = useState<Partial<IPaginationClient>>({
    page: 1,
    pageSize: 10,
  });
  const { t } = useTranslation();
  const menuItems = useMemo(
    () => [
      {
        key: EnumActionForm.delete,
        label: t('btn_delete'),
        icon: <Icons.TrashOpen />,
      },
      {
        key: EnumActionForm.edit,
        label: t('btn_edit'),
        icon: <Icons.EditPencil />,
      },
    ],
    [],
  );
  const handleRowClick = useCallback(
    item => (menu?: any) => {
      onActionRecord?.({ record: item, action: menu?.key });
      return;
    },
    [onActionRecord],
  );
  const poperContent = useCallback(
    item => {
      return (
        <Menu
          items={menuItems}
          style={{ borderInlineEnd: 'none' }}
          onClick={handleRowClick(item)}
        />
      );
    },
    [menuItems],
  );
  const columns = useMemo(
    () => [
      ...(columnsUsers || []).map(TableHelpers.renderCol),
      {
        title: '',
        dataIndex: '',
        key: 'x',
        render: item => {
          return (
            <Popover content={poperContent(item)}>
              <MoreOutlined />
            </Popover>
          );
        },
      },
    ],
    [poperContent],
  );

  useEffect(() => {
    pagination && onGetRecords({ pagination });
  }, [pagination]);

  useEffect(() => {
    isRefresh && pagination && onGetRecords({ pagination });
  }, [isRefresh]);
  return (
    <DataTable
      containerClass='f-width pt-3 pb-3 calcu-height'
      columns={columns}
      dataSource={records?.data || []}
      loading={records?.isLoading}
      pagination={{
        current: pagination?.page,
        pageSize: pagination?.pageSize,
        total: records?.pagination?.totalCount,
        pageSizeOptions: [],
        responsive: true,
        onChange(page, pageSize) {
          setPagination({ page, pageSize });
        },
      }}
    />
  );
};

export default memo(Content);
