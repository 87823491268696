/** @format */

import { IHomeActionPayload } from 'types';
import { EnumPrefixBaseActionType } from 'utils';

export interface IParamsAction {
  payload?: IHomeActionPayload['payload'];
  type?: string;
  callback?: IHomeActionPayload['callback'];
}

export const baseActionRequest = ({
  payload,
  type = '/' + EnumPrefixBaseActionType.GET_BASE_ACTIONS,
  callback,
}: IParamsAction) => ({
  payload,
  type,
  callback,
});
