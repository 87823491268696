/** @format */

import React, { memo, useCallback, useMemo } from 'react';
import { Layout, Menu, Row, Typography } from 'antd';
import { MenuItemType } from 'antd/es/menu/hooks/useItems';
import { useNavigate } from 'react-router-dom';
import theme from 'theme';
import { menuSiders } from 'utils';
import './styles.scss';
import { useCommon } from 'hooks';
import { Text } from 'components';
import Icons from 'assets/icons';
const SiderCus = () => {
  const navigate = useNavigate();
  const { openSider } = useCommon();

  const handleClickMenu: MenuItemType['onClick'] = useCallback(item => {
    const url = item?.keyPath?.reverse()?.join('/');
    navigate(url);
  }, []);

  const menuItems = useMemo(() => {
    return menuSiders?.map(menu => {
      const Icon = Icons?.[menu?.['icon'] || ''] || undefined;
      return {
        key: `${menu.key}`,
        label: <Text useI18n>{menu?.title}</Text>,
        icon: Icon && <Icon />,
      };
    });
  }, []);
  return (
    <Layout.Sider
      width={305}
      theme='light'
      style={{ padding: openSider ? 24 : 8 }}
      className='layout-sider'
      collapsed={!openSider}
    >
      <Row style={{ width: '100%' }}>
        {/* <Dropdown menu={{ items: [] }}>
          <Button
            style={{
              margin: 'auto',
              marginBottom: 20,
              width: '100%',
              backgroundColor: theme.colors.base.main,
              color: theme.colors.text.white,
              fontSize: 16,
              fontWeight: 600,
              padding: '12px 8px',
              height: 'fit-content',
              overflow: 'hidden',
            }}
          >
            PetroVietnam Landmark
            <DownOutlined />
          </Button>
        </Dropdown> */}
        {/* : (
          <Menu
            style={{ width: '100%', borderInlineEnd: 'none' }}
            mode='vertical'
            theme='light'
            items={menuSiders}
            getPopupContainer={node => node.parentNode as HTMLElement}
          />
        ) */}
      </Row>
      <Typography
        style={{ textAlign: 'left', fontSize: 16, fontWeight: 600, color: theme.colors.text.grey }}
      >
        Menu
      </Typography>
      <Menu
        style={{ borderInlineEnd: 'none' }}
        defaultSelectedKeys={['1']}
        defaultOpenKeys={['sub1']}
        mode='inline'
        theme='light'
        onClick={handleClickMenu}
        items={menuItems}
        className='menu-sider'
      />
    </Layout.Sider>
  );
};

export default memo(SiderCus);
