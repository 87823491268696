/** @format */

import React, { createContext, useReducer, useContext } from 'react';
import { IContextState, ILayoutActionsType } from 'types';

const LayoutStateContext = createContext<IContextState>({
  members: [],
  openSider: true,
});
const LayoutDispatchContext = createContext<any>(null);

function layoutReducer(state, action) {
  switch (action.type) {
    case ILayoutActionsType.UPDATE_PARAMS:
      return {
        ...state,
        ...action?.payload,
      };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function LayoutProvider({ children }: any) {
  const [state, dispatch] = useReducer(layoutReducer, {
    openSider: true,
  } as IContextState);

  return (
    <LayoutStateContext.Provider value={state}>
      <LayoutDispatchContext.Provider value={dispatch}>{children}</LayoutDispatchContext.Provider>
    </LayoutStateContext.Provider>
  );
}

function useLayoutState(): IContextState {
  const context = useContext(LayoutStateContext);
  if (!context) {
    throw new Error('useLayoutState must be used within a LayoutProvider');
  }
  return context;
}

function useLayoutDispatch() {
  const context = useContext(LayoutDispatchContext);
  if (context === undefined) {
    throw new Error('useLayoutDispatch must be used within a LayoutProvider');
  }
  return context;
}

export { LayoutProvider, useLayoutState, useLayoutDispatch, dispatchLayoutActions };

// ###########################################################

function dispatchLayoutActions({ type = ILayoutActionsType.UPDATE_PARAMS, payload }, dispatch) {
  return dispatch({
    type,
    payload,
  });
}
