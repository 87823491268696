/** @format */

import { EnumPrefixBaseActionType } from 'utils';

export const notification = {
  GET_BASE_ACTIONS: `notification/${EnumPrefixBaseActionType.GET_BASE_ACTIONS}`,
  POST_BASE_ACTIONS: `notification/${EnumPrefixBaseActionType.POST_BASE_ACTIONS}`,
  DELETE_BASE_ACTIONS: `notification/${EnumPrefixBaseActionType.DELETE_BASE_ACTIONS}`,
  PUT_BASE_ACTIONS: `notification/${EnumPrefixBaseActionType.PUT_BASE_ACTIONS}`,
  PATCH_BASE_ACTIONS: `notification/${EnumPrefixBaseActionType.PATCH_BASE_ACTIONS}`,
};
