/** @format */

import { IColumnsTableDefined, IBaseInput, IInputDatetime } from 'types';
import { TimeHelpers } from 'utils/helpers';

export const columnsPromotion: Partial<IColumnsTableDefined>[] = [
  {
    title: 'lbl_promotion_code',
    dataIndex: 'code',
    key: 'code',
    fixed: 'left',
  },
  {
    title: 'lbl_type',
    dataIndex: 'promotion_type',
    key: 'promotion_type',
  },
  {
    title: 'lbl_value',
    dataIndex: 'promotion_value',
    key: 'promotion_value',
  },
  {
    title: 'lbl_count',
    dataIndex: 'count',
    key: 'count',
  },
  {
    title: 'lbl_status',
    dataIndex: 'status',
    key: 'status',
  },
  {
    title: 'lbl_start_date',
    dataIndex: 'start_date',
    key: 'start_date',
    format(value) {
      return TimeHelpers.formatDate(value, 'X');
    },
  },
];

export const inputFormPromotion: (IBaseInput | IInputDatetime)[] = [
  {
    name: 'name',
    title: 'lbl_name',
    useI18nTitle: true,
    placeholder: 'lbl_name',
    useI18nPlaceholder: true,
    required: true,
    formItemProps: {
      rules: [{ required: true, message: 'msg_name_required', useI18n: true }],
    },
  },
];

export const inputPromotionFilters = columnsPromotion.map((item): IBaseInput => {
  return {
    key: item.key,
    title: item.title,
    useI18nTitle: true,
    placeholder: item.title,
    useI18nPlaceholder: true,
  };
});
export const dropdownFilterPromotions = columnsPromotion.map(item => {
  return {
    key: item.key,
    title: item.title,
    useI18nTitle: true,
  };
});
