/** @format */

import React, { FC, memo, useCallback } from 'react';
import { Header } from './Header';
import { Col, Form, Row } from 'antd';
import { Input } from 'components';
import { EnumActionForm, EnumTableAction, inputFormRestaurant } from 'utils';
import { useRestaurant } from 'hooks';

interface IResidentEditPage {
  open?: boolean;
  onClose?: () => void;
  onRefresh?: () => void;
  record?: any;
  onActionClick?: (action: string) => void;
}
const ResidentEdit: FC<IResidentEditPage> = ({ onActionClick, record }) => {
  const { onAddUpdateRecord } = useRestaurant();
  const [formAntd] = Form.useForm();
  const handleSubmit = useCallback(
    payload => {
      const form = record ? { id: record.id, ...payload } : payload;
      onAddUpdateRecord(form, res => {
        if (res.status === 200 || res.status === 201) onActionClick?.(EnumActionForm.cancel);
      });
    },
    [formAntd, record],
  );
  const handleActionClick = useCallback(
    (act: EnumTableAction | EnumActionForm) => {
      if (act === EnumActionForm.save) {
        formAntd.submit();
        return;
      }
      onActionClick?.(act);
    },
    [onActionClick, formAntd],
  );

  return (
    <Col className='f-width'>
      <Form
        onFinish={handleSubmit}
        initialValues={{
          ...record,
          bankInformation: {
            bankName: record?.bank_information?.bank_name || '',
            accountName: record?.bank_information?.account_name || '',
            accountNumber: record?.bank_information?.account_number || '',
          },
          openTime: {
            day: record?.open_time?.day,
            time: record?.open_time?.time,
          },
        }}
        form={formAntd}
      >
        <Header onActionClick={handleActionClick} record={record} />
        <Row justify={'space-between'} className='f-width'>
          {/* <Input
            variant={EnumInputVariant.combobox}
            optionsConfig={{
              endPoint: API_ENDPOINT.RESTAURANT.ALL,
              valueKey: 'id',
              titleKey: 'name',
            }}
            defaultValue={'35a95841-c9c0-46fa-bb8a-edf144e0ce57'}
          /> */}
          {inputFormRestaurant?.map(input => (
            <Input
              key={input?.['name']}
              // formItemProps={{
              //   ...input?.formItemProps,
              //   name: (input?.formItemProps?.name || input.name) as string,
              // }}
              {...input}
              containerClass='haft-width'
            />
          ))}
        </Row>
      </Form>
    </Col>
  );
};

export default memo(ResidentEdit);
