/** @format */

import { InputNumber } from 'antd';
import React, { forwardRef, memo } from 'react';
import { IInputNumber } from 'types';
const BaseInput = forwardRef<HTMLInputElement, IInputNumber>(function InputWithRef(
  { type = 'number', className = '', ...props },
  ref,
) {
  return <InputNumber {...props} ref={ref} type={type} className={`f-width ${className}`} />;
});

export default memo(BaseInput);
