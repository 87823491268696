/** @format */

import { FC, memo, useCallback, useEffect, useState } from 'react';
import Icons from 'assets/icons';
import { Input, Modal } from 'components';
import { EnumButtonVariant, EnumInputVariant, EnumVariantModal } from 'utils';
import { Col, Form, Upload } from 'antd';
import './styles.scss';
import { useCommon, useUser } from 'hooks';
import { PlusOutlined } from '@ant-design/icons';

interface IUserInfor {
  open?: boolean;
  onClose?: () => void;
}

const UserInfor: FC<IUserInfor> = ({ open, onClose }) => {
  const { onGetImageCDN } = useCommon();
  const { profile: userProfile, getUserProfile, onUpdateUserProfile } = useUser();
  const [formAntd] = Form.useForm();
  const [isEdit, setEdit] = useState<boolean>(false);
  const [previewAvatar, setPreviewAvatar] = useState<string>();

  const handleToggleEdit = useCallback(() => setEdit(s => !s), []);
  const handleSubmit = useCallback(
    formData =>
      onUpdateUserProfile({ ...formData, id: userProfile?.data?.['id'] }, () => {
        handleToggleEdit();
      }),
    [userProfile],
  );
  const handleChangeDate = useCallback((_, dateString) => {
    formAntd.setFieldValue('birthday', dateString);
  }, []);
  const handleSelectImage = useCallback(({ file: { originFileObj } }) => {
    setPreviewAvatar(URL.createObjectURL(originFileObj));
    formAntd.setFieldValue('avatar', originFileObj);
  }, []);

  useEffect(() => {
    open && getUserProfile();
  }, [open]);

  useEffect(() => {
    userProfile?.data &&
      onGetImageCDN({ path: userProfile?.data?.avatar, format: 'webp' }, fileURL => {
        setPreviewAvatar(fileURL);
      });
  }, [userProfile]);

  return (
    <Modal
      open={open}
      variant={EnumVariantModal.right}
      header={{
        btnAction: isEdit ? 'btn_save' : 'btn_edit',
        btnProps: {
          useI18n: true,
          icon: !isEdit ? <Icons.Edit /> : undefined,
          onClick: !isEdit ? handleToggleEdit : formAntd.submit,
          variant: isEdit ? EnumButtonVariant.save : undefined,
          iconProps: {
            width: 20,
            height: 20,
          },
          titleProps: {
            style: isEdit ? { color: 'white' } : {},
          },
          htmlType: isEdit ? 'submit' : undefined,
        },
      }}
      onCancel={onClose}
    >
      {userProfile?.data && (
        <Col>
          <Form
            initialValues={{
              ...userProfile?.data,
            }}
            form={formAntd}
            onFinish={handleSubmit}
          >
            <Col className='card-profile pb-2 mb-2'>
              <Form.Item name='avatar'>
                <Upload
                  name='avatar'
                  listType='picture-circle'
                  className='avatar-picker'
                  showUploadList={false}
                  accept={'image/*'}
                  onChange={handleSelectImage as any}
                  disabled={!isEdit}
                  fileList={[
                    {
                      ...formAntd.getFieldValue('avatar'),
                      url: previewAvatar,
                    },
                  ]}
                >
                  {previewAvatar ? (
                    <img src={previewAvatar} alt='avatar' style={{ width: '100%' }} />
                  ) : (
                    <PlusOutlined />
                  )}
                </Upload>
              </Form.Item>
            </Col>
            <Col>
              <Input
                useI18nTitle
                title={'lbl_username'}
                required
                formItemProps={{
                  required: true,
                  name: 'user_name',
                }}
                disabled={!isEdit}
              />
              <Input
                title='lbl_fullname'
                useI18nTitle
                formItemProps={{
                  name: 'full_name',
                }}
                disabled={!isEdit}
              />
              <Input
                title='lbl_email'
                useI18nTitle
                formItemProps={{
                  rules: [{ type: 'email', required: true }],
                  name: 'email',
                }}
                required
                disabled={!isEdit}
              />
              <Input
                title='lbl_account_bank'
                useI18nTitle
                formItemProps={{
                  name: 'account_bank',
                }}
                disabled={!isEdit}
              />
              <Input
                title='lbl_birthday'
                useI18nTitle
                formItemProps={{
                  name: 'birthday',
                }}
                onChange={handleChangeDate}
                variant={EnumInputVariant.datetime}
                disabled={!isEdit}
              />
              <Input
                title='lbl_address'
                useI18nTitle
                formItemProps={{
                  name: 'address',
                }}
                disabled={!isEdit}
              />
            </Col>
          </Form>
        </Col>
      )}
    </Modal>
  );
};

export default memo(UserInfor);
