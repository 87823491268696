/** @format */

import { combineReducers } from 'redux';
import notify from './notify/Reducer';
import user from './reducers/user';
import restaurant from './reducers/restaurant';
import restaurant_catalog from './reducers/restaurant_catalog';
import notification from './reducers/notification';
import promotion from './reducers/promotion';
import support from './reducers/support';

export default combineReducers({
  notify,
  user,
  restaurant,
  restaurant_catalog,
  notification,
  promotion,
  support,
});
