/** @format */

import { EnumPrefixBaseActionType } from 'utils';

export const user = {
  GET_BASE_ACTIONS: `user/${EnumPrefixBaseActionType.GET_BASE_ACTIONS}`,
  POST_BASE_ACTIONS: `user/${EnumPrefixBaseActionType.POST_BASE_ACTIONS}`,
  DELETE_BASE_ACTIONS: `user/${EnumPrefixBaseActionType.DELETE_BASE_ACTIONS}`,
  USER_LOGOUT: 'user/LOGOUT',
};
