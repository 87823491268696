/** @format */

import React, { FC, memo } from 'react';
import { Col, Modal as AntdModal, Row } from 'antd';
import { IModal } from 'types';
import { Button } from 'components';

const BaseModal: FC<IModal> = ({ children, buttonList, buttonsProps, ...props }) => {
  return (
    <AntdModal {...props}>
      <Col className='py-1'>
        {children}
        <Row className='footer-model mt-1 d-flex justify-end'>
          {buttonList?.map((btn, idx) => {
            return (
              <Button
                {...buttonsProps?.[btn]}
                key={btn + idx}
                variant={btn}
                title={`btn_${btn}`}
                useI18n={true}
                className={`${idx ? 'ml-2' : ''} ${buttonsProps?.[btn]?.className}`}
              />
            );
          })}
        </Row>
      </Col>
    </AntdModal>
  );
};

export default memo(BaseModal);
