/** @format */

import React, { FC, memo, useCallback } from 'react';
import { Col, Row } from 'antd';
import Icons from 'assets/icons';
import { Button, Text } from 'components';
import { EnumActionForm, EnumButtonVariant } from 'utils';
import '../styles.scss';

interface IHeaderResidentEdit {
  onActionClick?: (action: EnumActionForm) => void;
  record?: any;
}

const Header: FC<IHeaderResidentEdit> = ({ onActionClick, record }) => {
  const handleActionClick = useCallback(action => () => onActionClick?.(action), [onActionClick]);
  return (
    <Row justify={'space-between'} align={'middle'} className='mb-6'>
      <Col>
        <Row align={'middle'}>
          <Icons.ArrowLeft
            className='cursor-pointer'
            onClick={handleActionClick(EnumActionForm.cancel)}
          />
          <Text useI18n className='font-18 font-weight-6 ml-2'>
            {record ? 'lbl_user_edit_info' : 'lbl_user_add_info'}
          </Text>
        </Row>
      </Col>
      <Col>
        <Button
          className='mr-3'
          title='btn_cancel'
          useI18n
          variant={EnumButtonVariant.cancel}
          onClick={handleActionClick(EnumActionForm.cancel)}
        />
        <Button
          title='btn_save'
          useI18n
          variant={EnumButtonVariant.save}
          onClick={handleActionClick(EnumActionForm.save)}
        />
      </Col>
    </Row>
  );
};

export default memo(Header);
