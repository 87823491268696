/**
 * @format
 * @description define an object contains all the sources
 */

import en from './en';
import vi from './vi';

export const resources = {
  en: {
    translation: en,
  },
  vi: {
    translation: vi,
  },
};
