/** @format */

import { Row, Table, Tooltip } from 'antd';
import React, { forwardRef, memo, useCallback, useMemo, useRef } from 'react';
import { IDataTable } from 'types';
import './styles.scss';
import { EnumTableAction, TableHelpers } from 'utils';
import Icons from 'assets/icons';
import { ColumnType } from 'antd/es/table';
import { Button, Text } from 'components';
import { useTranslation } from 'react-i18next';
import { Pagination } from './components';

const DataTable = forwardRef<HTMLDivElement, IDataTable>(function TableCus(
  {
    columns,
    columnDefines,
    actions,
    containerStyles,
    containerClass = '',
    className = '',
    pagination,
    onTableActionClick,
    scroll = { x: 1000 },
    ...props
  },
  ref,
) {
  const { t } = useTranslation();
  const IconTable = useRef({
    [EnumTableAction.delete]: Icons.Trash,
    [EnumTableAction.edit]: Icons.EditSecond,
    [EnumTableAction.view]: Icons.ViewRecord,
  }).current;

  const handleActionClick = useCallback(
    (key: EnumTableAction) => item => {
      onTableActionClick?.({ action: key, record: item });
    },
    [onTableActionClick],
  );
  const renderIcon = useCallback(
    (key: EnumTableAction, item) => {
      const IconComponent = IconTable[key];
      return (
        !!IconComponent && (
          <Tooltip title={t(`btn_${key}`)}>
            <Button
              type='text'
              onClick={() => handleActionClick(key)(item)}
              shape='circle'
              icon={<IconComponent />}
            />
          </Tooltip>
        )
      );
    },
    [IconTable, handleActionClick],
  );
  const columnsTable = useMemo(() => {
    if (columnDefines)
      return [
        ...(columnDefines || []).map(TableHelpers.renderCol),
        actions && {
          title: <Text useI18n>lbl_action</Text>,
          dataIndex: '',
          key: 'x',
          fixed: 'right',
          width: 130,
          render: item => {
            return (
              <Row align={'middle'} wrap={false}>
                {actions?.map(act => renderIcon(act, item))}
              </Row>
            );
          },
        },
      ] as ColumnType<any>[];
    return columns || [];
  }, [columnDefines, columns, actions]);
  return (
    <>
      <Row style={containerStyles} className={`table-container ${containerClass}`}>
        <Table
          {...props}
          scroll={scroll}
          columns={columnsTable}
          pagination={false}
          className={`data-table ${className}`}
          ref={ref}
        />
      </Row>
      {pagination && <Pagination {...pagination} />}
    </>
  );
});

export default memo(DataTable);
