/** @format */

import { Pagination, Row } from 'antd';
import React, { forwardRef, memo, useCallback } from 'react';
import { ITablePagination } from 'types';
import './styles.scss';
import { Button } from 'components/Button';
import Icons from 'assets/icons';
import { useCommon } from 'hooks';
const PaginationCustom = forwardRef<HTMLDivElement, ITablePagination>(function Paginate({
  showSizeChanger = true,
  showQuickJumper = true,
  total,
  current = 1,
  pageSize = 10,
  ...props
}) {
  const { openSider } = useCommon();
  const customPagination: ITablePagination['itemRender'] = useCallback(
    (_, type, originalElement) => {
      if (type === 'prev') {
        return (
          <Button
            title='btn_previous'
            className='d-flex align-center'
            useI18n
            type='default'
            icon={<Icons.ArrowPrevious className='mr-3' />}
          />
        );
      }
      if (type === 'next') {
        return (
          <Button
            title='btn_next'
            className='d-flex align-center'
            useI18n
            type='default'
            rightIcon={<Icons.ArrowNext className='ml-3' />}
          />
        );
      }
      return originalElement;
    },
    [],
  );
  return (
    <Row
      justify={'center'}
      align={'middle'}
      className='custom-pagination'
      style={{ paddingLeft: openSider ? 305 + 8 : 80 + 8 }}
    >
      <Pagination
        {...props}
        total={total}
        current={current}
        pageSize={pageSize}
        showSizeChanger={showSizeChanger}
        showQuickJumper={showQuickJumper}
        itemRender={customPagination}
      />
    </Row>
  );
});

export default memo(PaginationCustom);
