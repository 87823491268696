/** @format */

import React, { memo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { NotifySelectors } from 'store/notify';
import { useNotify } from 'hooks';
import { message } from 'antd';

const Alert = ({ children }: any) => {
  const [messageApi, contextHolder] = message.useMessage();

  const { hide } = useNotify();

  const content = useSelector(NotifySelectors.getMessage);
  const type = useSelector(NotifySelectors.getType);

  useEffect(() => {
    const show = () => {
      messageApi.open({
        type: type,
        content,
      });
    };
    !hide && show();
  }, [type, content, hide]);

  return (
    <>
      {contextHolder}
      {children}
    </>
  );
};

export default memo(Alert);
