/** @format */

import {
  IBaseInput,
  IColumnsTableDefined,
  IInputDatetime,
  IInputCombobox,
  IInputComboboxOptionDefine,
  IInputUpload,
} from 'types';
import { EnumInputDatimeParseTo, EnumInputVariant, TimeHelpers } from 'utils';

export const columnsUsers: Partial<IColumnsTableDefined>[] = [
  {
    title: 'lbl_full_name',
    valueProps: {
      className: 'pointer underline font-14 font-weight-6',
    },
    dataIndex: 'full_name',
    key: 'full_name',
  },
  // {
  //   title: 'lbl_avatar',
  //   dataIndex: 'avatar',
  //   key: 'avatar',
  //   isElement: true,
  //   format(value) {
  //     return (
  //       !!value && (
  //         <Image
  //           src={`${process.env.REACT_APP_BASE_URI}/images?path=${value}`}
  //           height={70}
  //           alt={value}
  //         />
  //       )
  //     );
  //   },
  // },
  {
    title: 'lbl_user_name',
    dataIndex: 'user_name',
    key: 'user_name',
  },
  {
    title: 'lbl_phone_number',
    dataIndex: 'phone_number',
    key: 'phone_number',
  },
  {
    title: 'lbl_email',
    dataIndex: 'email',
    key: 'email',
  },

  {
    title: 'lbl_birthday',
    dataIndex: 'birthday',
    key: 'birthday',
    format(value) {
      return TimeHelpers.formatDate(value, 'X');
    },
  },

  {
    title: 'lbl_address',
    dataIndex: 'address',
    key: 'address',
  },
  {
    title: 'lbl_role',
    dataIndex: 'role',
    key: 'role',
  },
];

export const optionUserRoles: Partial<IInputComboboxOptionDefine>[] = [
  { value: 'ADMIN', title: 'ROLE_ADMIN', useI18n: true },
  { value: 'MANAGER', title: 'ROLE_MANAGER', useI18n: true },
  { value: 'DRIVER', title: 'ROLE_DRIVER', useI18n: true },
  { value: 'USER', title: 'ROLE_USER', useI18n: true },
];
export const optionUserGender: Partial<IInputComboboxOptionDefine>[] = [
  { value: 'MALE', title: 'GENDER_MALE', useI18n: true },
  { value: 'FEMALE', title: 'GENDER_FEMALE', useI18n: true },
  { value: 'UNKNOWN', title: 'GENDER_UNKNOWN', useI18n: true },
];
export const optionDriverVehicle: Partial<IInputComboboxOptionDefine>[] = [
  { value: 'MOTORBIKE', title: 'VEHICLE_MOTORBIKE', useI18n: true },
  { value: 'CAR', title: 'VEHICLE_CAR', useI18n: true },
  { value: 'NONE', title: 'VEHICLE_NONE', useI18n: true },
];

export const listInputUploadIdentification = [
  { title: 'identification_front', name: 'identificationFront' },
  { title: 'identification_back', name: 'identificationBack' },
].map(
  input =>
    ({
      variant: EnumInputVariant.upload,
      name: input.name,
      listType: 'picture-card',
      title: `lbl_${input.title}`,
      useI18nTitle: true,
      formItemProps: {
        name: input.name,
      },
    } as IInputUpload),
);
export const listInputUploadLicense = [
  { title: 'license_front', name: 'licenseFront' },
  { title: 'license_back', name: 'licenseBack' },
].map(
  input =>
    ({
      variant: EnumInputVariant.upload,
      name: input.name,
      listType: 'picture-card',
      title: `lbl_${input.title}`,
      useI18nTitle: true,
      formItemProps: {
        name: input.name,
      },
    } as IInputUpload),
);

export const inputUsersUpload: IInputUpload[] = [
  {
    variant: EnumInputVariant.upload,
    name: 'avatar',
    title: 'lbl_avatar',
    useI18nTitle: true,

    formItemProps: {
      name: 'avatar',
    },
  },
  // ...listInputUploadIdentification.map(
  //   input =>
  //     ({
  //       variant: EnumInputVariant.upload,
  //       name: input.name,
  //       listType: 'picture-card',
  //       title: `lbl_${input.title}`,
  //       useI18nTitle: true,
  //       formItemProps: {
  //         name: input.name,
  //       },
  //     } as IInputUpload),
  // ),
];

export const inputAddUsersList: (IBaseInput | IInputCombobox | IInputDatetime)[] = [
  {
    name: 'phoneNumber',
    title: 'lbl_phone_number',
    placeholder: 'lbl_phone_number',
    useI18nPlaceholder: true,
    useI18nTitle: true,
    formItemProps: {
      rules: [{ required: true }],
      name: 'phoneNumber',
    },
    required: true,
  },
  {
    name: 'userName',
    title: 'lbl_user_name',
    placeholder: 'lbl_user_name',
    useI18nPlaceholder: true,
    useI18nTitle: true,
    formItemProps: {
      name: 'userName',
    },
  },

  {
    name: 'fullName',
    title: 'lbl_full_name',
    placeholder: 'lbl_full_name',
    useI18nPlaceholder: true,
    useI18nTitle: true,
    formItemProps: {
      name: 'fullName',
    },
  },
  {
    name: 'password',
    title: 'lbl_password',
    placeholder: 'lbl_password',
    useI18nPlaceholder: true,
    useI18nTitle: true,
    formItemProps: {
      rules: [{ min: 6 }],
      name: 'password',
    },
    variant: EnumInputVariant.password,
  },
  {
    name: 'email',
    title: 'lbl_email',
    placeholder: 'lbl_email',
    useI18nPlaceholder: true,
    useI18nTitle: true,
    formItemProps: {
      name: 'email',
    },
  },
  {
    name: 'accountBank',
    title: 'lbl_account_bank',
    placeholder: 'lbl_account_bank',
    useI18nPlaceholder: true,
    useI18nTitle: true,
    formItemProps: {
      name: 'accountBank',
    },
  },
  {
    name: 'address',
    title: 'lbl_address',
    placeholder: 'lbl_address',
    useI18nPlaceholder: true,
    useI18nTitle: true,
    formItemProps: {
      name: 'address',
    },
  },
  {
    name: 'birthday',
    title: 'lbl_birthday',
    placeholder: 'lbl_birthday',
    useI18nPlaceholder: true,
    useI18nTitle: true,
    formItemProps: {
      name: 'birthday',
    },
    variant: EnumInputVariant.datetime,
    parseTo: EnumInputDatimeParseTo.Unit,
  },
  {
    name: 'role',
    title: 'lbl_role',
    placeholder: 'lbl_role',
    useI18nPlaceholder: true,
    useI18nTitle: true,
    formItemProps: {
      name: 'role',
    },
    variant: EnumInputVariant.combobox,
    optionsDefine: optionUserRoles,
  },
  {
    name: 'gender',
    title: 'lbl_gender',
    placeholder: 'lbl_gender',
    useI18nPlaceholder: true,
    useI18nTitle: true,
    formItemProps: {
      name: 'gender',
    },
    variant: EnumInputVariant.combobox,
    optionsDefine: optionUserGender,
  },
];
