/** @format */

import { Row, Col } from 'antd';
import { Text, Button } from 'components';
import { useUser } from 'hooks';
import React, { FC, memo, useCallback } from 'react';
import { EnumActionForm, EnumButtonVariant } from 'utils';
interface IHeaderApartmentEdit {
  onActionClick?: (action: EnumActionForm) => void;
}
const Header: FC<IHeaderApartmentEdit> = ({ onActionClick }) => {
  const { records } = useUser();
  const handleActionClick = useCallback(
    (action: EnumActionForm) => () => onActionClick?.(action),
    [onActionClick],
  );

  return (
    <Row className='page-header'>
      <Col>
        <Text useI18n className='header-title'>
          lbl_manage_user
        </Text>
        <Text
          useI18n
          className='font-12 count-apartment'
          replace={{ from: '{value}', to: records?.pagination?.totalCount || '' }}
        >
          lbl_user_count
        </Text>
      </Col>
      <Col>
        {/* <Button title='txt.file_export' useI18n variant={EnumButtonVariant.export} />
        <Button
          title='txt.file_import'
          useI18n
          variant={EnumButtonVariant.import}
          className='ml-6 mr-6'
        /> */}
        <Button
          title='txt.add'
          useI18n
          variant={EnumButtonVariant.add}
          onClick={handleActionClick(EnumActionForm.create)}
        />
      </Col>
    </Row>
  );
};

export default memo(Header);
