/** @format */

export enum EnumButtonVariant {
  base = 'base',
  export = 'export',
  import = 'import',
  add = 'add',
  search = 'search',
  cancel = 'cancel',
  save = 'save',
  filter = 'filter',
  add_filter = 'add_filter',
  done = 'done',
}
