/** @format */

import dayjs, { Dayjs } from 'dayjs';
import moment from 'moment';
import { EnumInputDatime } from 'utils/enums';

export const getTimeAfter = (mCount: number, unit?: moment.unitOfTime.DurationConstructor) =>
  moment().add(mCount, unit);

export const formatDate = (time: string | Date, strict: any = false, format = 'DD-MM-YYYY') => {
  try {
    const isNum = !isNaN(+time);
    return !!time && moment(isNum ? Number(time) : time, strict).format(format);
  } catch (error) {
    return null;
  }
};

export function checkTimeFormat(
  date?: string | Dayjs | null | Date,
  format = EnumInputDatime.format,
  locale = 'vi',
  strict = true,
) {
  try {
    return !!date && dayjs(date, format as string, locale, strict).isValid();
  } catch (error) {
    return false;
  }
}
