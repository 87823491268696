/** @format */

import React, { FC, memo } from 'react';
import { Col, Modal as AntdModal, Row } from 'antd';
import { IModal } from 'types';
import { EnumButtonVariant } from 'utils';
import { Button } from 'components';

const ConfirmModal: FC<IModal> = ({
  children,
  buttonList = [EnumButtonVariant.save, EnumButtonVariant.cancel],
  buttonsProps,
  ...props
}) => {
  return (
    <AntdModal {...props}>
      <Col className='py-1'>
        {children}
        <Row className='mt-1' justify={'end'}>
          {buttonList?.map((btn, idx) => {
            return (
              <Button
                {...buttonsProps?.[btn]}
                key={btn + idx}
                variant={btn}
                title={`btn_${btn}`}
                useI18n={true}
                className={`${idx ? 'ml-4' : ''} ${buttonsProps?.[btn]?.className}`}
              />
            );
          })}
        </Row>
      </Col>
    </AntdModal>
  );
};

export default memo(ConfirmModal);
