/** @format */

import { Breadcrumb } from 'antd';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { EMODULE_ROUTES } from 'utils';
const BreadCrumbs = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const pathSnippets = location.pathname.split('/').filter(i => i);

  const breadcrumbNameMap: Record<string, string> = Object.keys(EMODULE_ROUTES).reduce((p, c) => {
    p[c] = t(`lbl_${c || ''}`);
    return p;
  }, {});
  const extraBreadcrumbItems = pathSnippets
    .map((_, index) => {
      const url = `/${pathSnippets.slice(0, index + 1).join('/')}`;
      return (
        !!breadcrumbNameMap[url] && {
          key: url,
          title: breadcrumbNameMap[url],
        }
      );
    })
    ?.filter(i => i);
  const breadcrumbItems = [
    {
      title: <Link to=''>Home</Link>,
      key: 'home',
    },
  ].concat(extraBreadcrumbItems as any[]);
  return <Breadcrumb items={breadcrumbItems} className='mb-6' />;
};

export default memo(BreadCrumbs);
