/** @format */

import React, { FC, memo, useCallback, useMemo, useRef, useState } from 'react';
import { Row, Col, Dropdown } from 'antd';
import { Button, Checkbox, Input, Text } from 'components';
import { Header } from 'pages/main/module/customer_catalog/components/Header';
import {
  EnumActionForm,
  EnumButtonVariant,
  dropdownFilterApartments,
  inputRestaurantCatalogFilters,
} from 'utils';
import Icons from 'assets/icons';

interface IHeaderApartmentEdit {
  onActionClick?: (action: EnumActionForm) => void;
}
const HeaderFilter: FC<IHeaderApartmentEdit> = ({ onActionClick }) => {
  const initialFilter = useRef(
    inputRestaurantCatalogFilters.reduce((p, c, idx) => {
      p[c.key || ''] = { show: idx < 8, value: '' };
      return p;
    }, {}),
  );

  const [openDropdown, setOpenDropdown] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [filter, setFilter] = useState<Record<string, any>>(initialFilter.current);

  const menuDropdowns = useMemo(
    () =>
      dropdownFilterApartments.map(item => ({
        ...item,
        icon: (
          <Checkbox
            key={item.key}
            checked={!!filter?.[item?.key as string]?.show}
            title={item.title}
            useI18n={item.useI18nTitle}
          />
        ),
      })),
    [filter],
  );
  const handleToggleFilter = useCallback(() => {
    setShowFilter(s => !s);
  }, []);

  const handleActionClick = useCallback(
    (act: EnumActionForm) => {
      switch (act) {
        case EnumActionForm.filter:
          handleToggleFilter();
          break;
        default:
          onActionClick?.(act);
          break;
      }
    },
    [onActionClick],
  );

  const handleFilterchange = useCallback(
    (key: string) =>
      ({ target: { value } }) => {
        setFilter(s => ({ ...s, [key]: { ...s[key], value } }));
      },
    [],
  );

  const handleItemClick = useCallback(({ key }) => {
    setFilter(s => ({ ...s, [key]: { ...s[key], show: !s[key]?.show } }));
  }, []);

  const handleResetFilter = useCallback(() => {
    setFilter(initialFilter.current);
  }, [initialFilter.current]);

  const handleToggleDropdown = useCallback(
    (op?: boolean) => () => {
      setOpenDropdown(s => op || !s);
    },
    [],
  );
  return (
    <Col className='f-width mb-6'>
      <Row className='f-width'>
        <Header onActionClick={handleActionClick} />
      </Row>
      {showFilter && (
        <Col className='mt-3 p-4 radius-4 filter-page'>
          <Row align={'middle'} justify={'space-between'}>
            <Row align={'middle'}>
              <Icons.Filter />
              <Text useI18n className='ml-3 font-16'>
                lbl_filter
              </Text>
            </Row>
            <Row align={'middle'}>
              <Button
                title='btn_reset'
                onClick={handleResetFilter}
                useI18n
                type='default'
                className='mr-2'
              />
              <Dropdown
                open={openDropdown}
                autoFocus
                menu={{
                  items: menuDropdowns as any[],
                  onMouseEnter: handleToggleDropdown(true),
                  onMouseLeave: handleToggleDropdown(false),
                  onClick: handleItemClick,
                }}
              >
                <Button
                  useI18n
                  title='btn_add_filter'
                  onClick={handleToggleDropdown()}
                  variant={EnumButtonVariant.add_filter}
                />
              </Dropdown>
            </Row>
          </Row>
          <Row className='f-width mt-6' align={'middle'}>
            {inputRestaurantCatalogFilters.map(
              (input, idx) =>
                !!filter[input.key as string]?.show && (
                  <Input
                    {...input}
                    key={`${input.title}_${idx}`}
                    variant={input?.variant}
                    containerClass={`filter-item ${input?.className || ''}`}
                    isForm={false}
                    onChange={handleFilterchange(input.key as string)}
                    value={filter?.[input.key as string]?.value}
                  />
                ),
            )}
          </Row>
        </Col>
      )}
    </Col>
  );
};

export default memo(HeaderFilter);
