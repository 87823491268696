/** @format */

import React, { FC, memo } from 'react';
import { Col, Upload as UploadAntd } from 'antd';
import { IInputUpload } from 'types';
import { PlusOutlined } from '@ant-design/icons';
const Upload: FC<IInputUpload> = ({
  listType = 'picture-circle',
  accept = 'image/*',
  children,
  className = '',
  title,
  ...props
}) => {
  return (
    <Col className='wrap-upload'>
      <UploadAntd
        {...props}
        className={`${className} image-picker`}
        listType={listType}
        accept={accept}
      >
        {children || (
          <Col className='wrap-child'>
            <PlusOutlined />
          </Col>
        )}
      </UploadAntd>
      {title}
    </Col>
  );
};

export default memo(Upload);
