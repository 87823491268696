/** @format */

export enum EnumValueType {
  'String' = 'String',
  'Number' = 'Number',
  'Boolean' = 'Boolean',
  'Object' = 'Object',
  'Array' = 'Array',
  'Null' = 'Null',
  'Undefined' = 'Undefined',
  'Function' = 'Function',
}
export enum EnumActionForm {
  deposit = 'deposit',
  withdraw = 'withdraw',
  edit = 'edit',
  create = 'create',
  delete = 'delete',
  cancel = 'cancel',
  save = 'save',
  reset_password = 'reset_password',
  info = 'info',
  view = 'view',
  filter = 'filter',
  export = 'export',
  import = 'import',
}
