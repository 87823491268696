/** @format */

import React, { FC, memo, useCallback } from 'react';
import { Modal, Text } from 'components';
import { EnumActionForm, EnumVariantModal } from 'utils';
import { useUser } from 'hooks';
interface IConfirmForm {
  open?: boolean;
  onClose?: () => void;
  record?: any;
  action?: EnumActionForm;
  onActionRecord?: ({ action, record }) => void;
  onRefresh?: () => void;
}

const Confirm: FC<IConfirmForm> = ({ open, action, record, onClose, onRefresh }) => {
  const { onDeleteRecord } = useUser();
  const handleConfirmDelRecord = useCallback(() => {
    action === EnumActionForm.delete &&
      onDeleteRecord(record, res => {
        if (res.status === 200) {
          onRefresh?.();
          onClose?.();
        }
      });
  }, [action, record]);
  return (
    <Modal
      open={open}
      title={'lbl_confirm'}
      useI18n
      variant={EnumVariantModal.confirm}
      buttonsProps={{
        save: {
          onClick: handleConfirmDelRecord,
        },
        cancel: {
          onClick: onClose,
        },
      }}
      onCancel={onClose}
    >
      <Text
        className='my-2'
        replace={{
          from: '{record}',
          to: (record?.full_name || '')?.toUpperCase(),
        }}
        useI18n
      >
        msg_confirm_delete_record
      </Text>
    </Modal>
  );
};

export default memo(Confirm);
