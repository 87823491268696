/** @format */

import React, { forwardRef, memo, useMemo, useRef } from 'react';
import { Button as AntdButton } from 'antd';
import { IButton } from 'types';
import { Text } from 'components';
import { EnumButtonVariant } from 'utils';
import Icons from 'assets/icons';
import theme from 'theme';
import './styles.scss';

const Button = forwardRef<HTMLButtonElement, IButton>(function ButtonRef(
  {
    variant = EnumButtonVariant.base,
    size = 'middle',
    icon,
    rightIcon,
    type,
    title,
    useI18n,
    children,
    titleProps,
    className = '',
    iconProps,
    ...props
  },
  ref,
) {
  const iconStyle = useMemo(
    () => ({
      marginRight: 8,
      verticalAlign: 'middle',
      color: theme.colors.icon.main,
      ...iconProps?.style,
    }),
    [iconProps?.style],
  );
  const inputType = useMemo(() => {
    const typeList = {
      [EnumButtonVariant.filter]: 'default',
      [EnumButtonVariant.export]: 'default',
      [EnumButtonVariant.import]: 'default',
      [EnumButtonVariant.add_filter]: 'default',
      [EnumButtonVariant.cancel]: 'default',
    };
    return type || typeList?.[variant] || 'primary';
  }, [type, variant]);
  const btnClassName = useMemo(
    () => `radius-4 pl-4 pr-4 btn-default btn-${variant} ${className}`,
    [className, variant],
  );
  const label = useMemo(() => {
    if (children) return children;
    return (
      <Text
        useI18n={useI18n}
        {...titleProps}
        className={`btn-title title-${variant} ${titleProps?.className || ''}`}
      >
        {title}
      </Text>
    );
  }, [children, title, variant, useI18n, titleProps]);

  const btnIcon = useRef({
    [EnumButtonVariant.add]: <Icons.Add {...iconProps} style={iconStyle} />,
    [EnumButtonVariant.add_filter]: (
      <Icons.Add fill={theme.colors.base.main} {...iconProps} style={iconStyle} />
    ),
    [EnumButtonVariant.export]: <Icons.Download {...iconProps} style={iconStyle} />,
    [EnumButtonVariant.import]: <Icons.Upload {...iconProps} style={iconStyle} />,
    [EnumButtonVariant.cancel]: <Icons.Close style={{ ...iconStyle }} {...iconProps} />,
    [EnumButtonVariant.filter]: <Icons.Filter {...iconProps} style={iconStyle} />,
    [EnumButtonVariant.save]: <Icons.Confirm color='white' {...iconProps} style={iconStyle} />,
    [EnumButtonVariant.search]: <Icons.Search {...iconProps} style={iconStyle} />,
  }).current;
  return (
    <AntdButton
      ref={ref}
      {...props}
      size={size}
      icon={icon || btnIcon[variant]}
      type={inputType}
      className={btnClassName}
    >
      {label}
      {rightIcon}
    </AntdButton>
  );
});

export default memo(Button);
