/** @format */

import { ColumnType } from 'antd/es/table';
import { Text } from 'components';
import { IColumnsTableDefined } from 'types';
import { DataHelpers } from '.';

function renderValue(props: Partial<IColumnsTableDefined>, val, rc, idx) {
  try {
    const { isElement, format, valueProps } = props;
    const functionRender = (value, record, index) => {
      if (DataHelpers.getDataType(format) === 'Function') {
        return (format as any)?.(value, record, index);
      }
      if (DataHelpers.getDataType(format) === 'String') return `${format}${value}`;
      if (!value) return '';
      return value;
    };
    return isElement ? (
      functionRender(val, rc, idx)
    ) : (
      <Text useI18n {...valueProps}>
        {functionRender(val, rc, idx)}
      </Text>
    );
  } catch (error) {
    return undefined;
  }
}

export function renderCol(props: Partial<IColumnsTableDefined>): ColumnType<any> {
  try {
    const { title, titleProps, key } = props;
    return {
      ...props,
      key,
      title: (
        <Text useI18n {...titleProps}>
          {title}
        </Text>
      ),
      render(value, record, index) {
        return renderValue(props, value, record, index);
      },
    };
  } catch (error) {
    return {};
  }
}
