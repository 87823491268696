/** @format */

import { put, select } from 'redux-saga/effects';
import { error } from 'store/notify';
import * as actionList from 'store/reducers';
import { IHomeActionPayload, INofifyState } from 'types';
import { axiosClient } from 'utils';

export function* onGetBaseActionsRequest(action: IHomeActionPayload) {
  const { requestDataStore } = actionList?.[action?.store] || {};
  try {
    const stateByStore = (yield select())?.[action?.store]; /* get state by store-key */
    const dataKey = action?.payload?.dataKey || '';
    const dataIsObject = action?.payload?.isObject;

    const isNotJSONResponse =
      action.payload.axiosConfig?.responseType &&
      action.payload.axiosConfig?.responseType !== 'json';

    yield !isNotJSONResponse &&
      action?.store &&
      requestDataStore &&
      put(
        requestDataStore?.({
          [dataKey]: {
            ...stateByStore?.[dataKey],
            isLoading: true,
            data: undefined,
          },
        }),
      );

    const rs = yield axiosClient.get(`${action.payload.endPoint}`, {
      params: action.payload.formData,
      ...action.payload.axiosConfig,
    });

    if (!action?.store || isNotJSONResponse) {
      action?.callback?.(rs);
      return;
    }
    if (rs.status === 200) {
      const dataFormat = {
        [dataKey]: {
          isLoading: false,
          data: dataIsObject ? rs.data?.result?.[0] : rs.data?.result,
          pagination: {
            totalCount: rs.data?.total,
          },
        },
      };
      yield put(requestDataStore?.(dataFormat));
      action?.callback?.(rs);
    }
  } catch (e: any) {
    yield put(
      error({
        message: 'some_thing_wrong',
        options: { useI18n: true },
      } as INofifyState),
    );
    yield requestDataStore &&
      put(
        requestDataStore?.({
          [action?.payload?.dataKey || '']: {
            isLoading: false,
            data: [],
            pagination: {
              totalCount: 0,
            },
          },
        }),
      );
    return action?.callback?.(e);
  }
}
