/** @format */

import React, { FC, memo, useCallback } from 'react';
import { Header } from './Header';
import { Col, Form, Row } from 'antd';
import { Input } from 'components';
import { EnumActionForm, EnumTableAction, inputFormRestaurantCatalog } from 'utils';
import { useCustomerCatalog } from 'hooks';

interface IResidentEditPage {
  open?: boolean;
  onClose?: () => void;
  onRefresh?: () => void;
  record?: any;
  onActionClick?: (action: string) => void;
}
const ResidentEdit: FC<IResidentEditPage> = ({ onActionClick, record }) => {
  const { onAddUpdateRecord } = useCustomerCatalog();
  const [formAntd] = Form.useForm();
  const handleSubmit = useCallback(
    payload => {
      const form = record ? { id: record.id, ...payload } : payload;
      onAddUpdateRecord(form, res => {
        if (res.status === 200 || res.status === 201) onActionClick?.(EnumActionForm.cancel);
      });
    },
    [formAntd, record],
  );
  const handleActionClick = useCallback(
    (act: EnumTableAction | EnumActionForm) => {
      if (act === EnumActionForm.save) {
        formAntd.submit();
        return;
      }
      onActionClick?.(act);
    },
    [onActionClick, formAntd],
  );

  return (
    <Col className='f-width'>
      <Form
        onFinish={handleSubmit}
        initialValues={{
          ...record,
        }}
        form={formAntd}
      >
        <Header onActionClick={handleActionClick} record={record} />
        <Row justify={'space-between'} className='f-width'>
          {inputFormRestaurantCatalog?.map(input => (
            <Input {...input} key={input?.['name']} containerClass='haft-width' />
          ))}
        </Row>
      </Form>
    </Col>
  );
};

export default memo(ResidentEdit);
