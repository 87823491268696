/** @format */

const defaultThemeColors = {
  base: {
    main: '#AF0D0E',
    dark: '#000000',
    light: '#FFFFFF',
  },
  text: {
    main: '#1C1243',
    light: '#858688',
    grey: '#64748B',
    brand_main: '#19ABA9',
    brand_light: '#61BD79',
    brand_dark: '#FFB901',
    white: '#FFFFFF',
  },
  background: {
    main: '#F4F4F4',
    light: '#FFFFFF',
    grey_light: '#EDEDED',
  },
  access: {
    success: '#64B300',
    error: '#FF3D3A',
    infor: '#EBD727',
    warning: '#F69240',
  },
  button: {
    main: '#EEEEEE',
    error: '#FF3D3A',
    btnlime: '#FFB901',
    cyan: '#19ABA9',
  },
  icon: {
    main: '#3A3A3C',
  },
  border: {
    main: '#EEEEEE',
  },
};

export { defaultThemeColors };
