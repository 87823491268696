/** @format */

import { EnumPrefixBaseActionType } from 'utils';

export const store_lookup = {
  GET_BASE_ACTIONS: `store_lookup/${EnumPrefixBaseActionType.GET_BASE_ACTIONS}`,
  POST_BASE_ACTIONS: `store_lookup/${EnumPrefixBaseActionType.POST_BASE_ACTIONS}`,
  DELETE_BASE_ACTIONS: `store_lookup/${EnumPrefixBaseActionType.DELETE_BASE_ACTIONS}`,
  PUT_BASE_ACTIONS: `store_lookup/${EnumPrefixBaseActionType.PUT_BASE_ACTIONS}`,
  PATCH_BASE_ACTIONS: `store_lookup/${EnumPrefixBaseActionType.PATCH_BASE_ACTIONS}`,
};
