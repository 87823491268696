/** @format */

import { EnumValueType } from 'utils/enums';
import { DataHelpers } from '.';

export function flattenObject(obj, prefix = '') {
  const flattened = {};

  for (const key in obj) {
    if (DataHelpers.getDataType(obj[key]) === EnumValueType.Object) {
      Object.assign(flattened, flattenObject(obj[key], prefix + key + '.'));
    } else {
      obj[key] && (flattened[prefix + key] = obj[key]);
    }
  }
  return flattened;
}

export function parseObjectToFormData(record) {
  try {
    const parsingRecord = flattenObject(record);
    const formData = new FormData();
    Object.keys(parsingRecord)?.map(key => {
      record[key] && formData.append(key, record[key]);
    });
    return formData;
  } catch (error) {
    return null;
  }
}
export const getDataType = (value: any): keyof typeof EnumValueType =>
  Object.prototype.toString.call(value).slice(8, -1) as keyof typeof EnumValueType;
