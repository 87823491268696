/** @format */

export const API_ENDPOINT = {
  // REQUEST_OTP: '/authen/request-otp',
  // VERIFY_OTP: '/authen/verify-otp',
  // CREATE_USER: 'authen/create-user',
  LOGIN: '/authen/login',
  LOGOUT: '/authen/logout',
  GET_PROFILE: 'authen/profile',
  // GET_SESSION: 'authen/get-session',
  // DRIVERS_CITY: 'group_driver',
  // DRIVER_DETAIL: 'group_driver_detail',
  // CREATE_CHAT_ROOM: 'chat-room/create',
  // DRIVER_REACT: 'drive/react',
  // GET_LIST_CHAT_BY_USER: 'chat-room/get-by-user',
  // CREATE_TICKET_IN_GROUP: 'drive',
  // GET_MESSAGE_BY_USER: 'chat/user-get-messages',
  // PICK_TICKET: '/drive/pick',
  // DRIVE: '/drive',
  USER: {
    USER: 'user/',
    UPDATE_PROFILE: 'user/update/',
  },
  ADMIN: {
    USER: {
      UPDATE: 'admin/user/update/',
      CREATE: 'admin/user/create/',
      DEPOSIT: 'admin/user/deposit/',
      WITH_DRAW: 'admin/user/withdraw/',
      ALL_USER: 'admin/user/get-all-user/',
      REMOVE_BY_ID: 'admin/user/remove-by-id',
    },
    TRANSACTION: 'transaction/admin',
  },
  RESTAURANT: {
    ALL: 'admin/restaurant/get-all',
    CREATE: 'admin/restaurant/create',
    UPDATE: 'admin/restaurant/update/',
    REMOVE: 'admin/restaurant/remove/',
  },
  RESTAURANT_CATALOG: {
    ALL: 'admin/restaurant-catalog/get-all',
    CREATE: 'admin/restaurant-catalog/create',
    UPDATE: 'admin/restaurant-catalog/update/',
    REMOVE: 'admin/restaurant-catalog/remove/',
  },
  NOTIFICATION: {
    ALL: 'admin/notification/get-all',
    CREATE: 'admin/notification/create',
    UPDATE: 'admin/notification/update/',
    REMOVE: 'admin/notification/remove/',
  },
  SUPPORT: {
    ALL: 'admin/support/get',
    CREATE: 'admin/support/create',
    UPDATE: 'admin/support/update/',
    REMOVE: 'admin/support/remove/',
  },
  CUSTOMER_CATALOG: {
    ALL: 'admin/customer-catalog/get-all',
    CREATE: 'admin/customer-catalog/create',
    UPDATE: 'admin/customer-catalog/update/',
    REMOVE: 'admin/customer-catalog/remove/',
  },
  PROMOTION: {
    ALL: 'admin/promotion/get-all',
    CREATE: 'admin/promotion/create',
    UPDATE: 'admin/promotion/update/',
    REMOVE: 'admin/promotion/remove/',
  },

  IMAGE_CDN: '/images',
};
