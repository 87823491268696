/** @format */

import React, { useEffect } from 'react';
import './App.scss';
import 'antd/dist/reset.css';

import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { ActivityIndicator } from 'components';
import { useUserState } from 'context/UserContext';
import i18n from 'i18n';
import { KEY_CONTEXT, ROUTES } from 'utils';
import { useKey } from 'hooks';
import { ELanguage } from 'types';

import { Login } from 'pages/auth';
import MainPage from 'pages/main/Mainpage';
import { Col, message, Row } from 'antd';

const App = (props: any) => {
  const { isAuth } = useUserState();
  const { getKey } = useKey();
  const langs = getKey(KEY_CONTEXT.LANGUAGE) ?? ELanguage.VI;
  const [, contextHolder] = message.useMessage();

  useEffect(() => {
    const loadLang = async () => {
      await i18n.changeLanguage(langs);
    };
    loadLang();
  }, [langs]);

  const loading = () => (
    <Row>
      <Col span={24}>
        <ActivityIndicator />
      </Col>
    </Row>
  );

  return (
    <Router basename='/'>
      <React.Suspense fallback={loading()}>
        {contextHolder}
        <Routes>
          <Route path={`${ROUTES.APP.ROOT}`} element={<Login />} />
          <Route
            path={`*`}
            element={
              !isAuth ? <Navigate replace to={`${ROUTES.APP.ROOT}`} /> : <MainPage {...props} />
            }
          />
        </Routes>
      </React.Suspense>
    </Router>
  );
};

export default App;
