/** @format */

import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { baseActionRequest } from 'store/actions';
import { IDataStore, IPaginationClient } from 'types';
import { API_ENDPOINT, DataHelpers } from 'utils';
import { supportActions } from 'store/types';
import { getAttributesByKey } from 'store/selectors';
import { message } from 'antd';

export function useSupport() {
  const dispatch = useDispatch();
  const loading = useSelector(getAttributesByKey('support', 'isLoading'));
  const records =
    (useSelector(getAttributesByKey('support', 'records')) as IDataStore<any[]>) || null;

  const onGetRecords = useCallback(
    ({
      pagination,
    }: { pagination?: Partial<Pick<IPaginationClient, 'page' | 'pageSize'>> } = {}) => {
      dispatch(
        baseActionRequest({
          payload: {
            dataKey: 'records',
            endPoint: API_ENDPOINT.SUPPORT.ALL,
            axiosConfig: {
              params: {
                limit: pagination?.pageSize,
                page: pagination?.page,
              },
            },
          },
          type: supportActions.GET_BASE_ACTIONS,
        }),
      );
    },
    [dispatch],
  );

  const onAddUpdateRecord = useCallback(
    async (payload: any, callback?: (res) => void) => {
      const { id = '', ...record } = payload;
      const formData = DataHelpers.flattenObject(record);
      const endPoint = id ? API_ENDPOINT.SUPPORT.UPDATE + id : API_ENDPOINT.SUPPORT.CREATE;
      dispatch(
        baseActionRequest({
          payload: {
            formData,
            endPoint,
            // axiosConfig: {
            //   headers: {
            //     'Content-Type': 'multipart/form-data',
            //   },
            // },
          },
          type: supportActions.POST_BASE_ACTIONS,
          callback: result => callback?.(result),
        }),
      );
    },
    [dispatch],
  );

  const onDeleteRecord = useCallback(
    async (payload: any, callback?: (res) => void) => {
      const { id } = payload;
      if (!id) return;
      const endPoint = `${API_ENDPOINT.SUPPORT.REMOVE}${id}`;
      dispatch(
        baseActionRequest({
          payload: {
            endPoint,
          },
          type: supportActions.DELETE_BASE_ACTIONS,
          callback: result => {
            if (result?.status === 200) message.success('Thành công');
            else message.error('Thất bại');
            callback?.(result);
          },
        }),
      );
    },
    [dispatch],
  );
  return {
    loading,
    records,

    onGetRecords,
    onAddUpdateRecord,
    onDeleteRecord,
  };
}
