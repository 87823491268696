/** @format */

export enum EnumInputVariant {
  base = 'base',
  number = 'number',
  datetime = 'datetime',
  search = 'search',
  upload = 'upload',
  combobox = 'combobox',
  password = 'password',
}
export enum EnumInputDatimeParseTo {
  ISOString = 'ISOString',
  Unit = 'Unit',
}
export enum EnumInputDatime {
  format = 'DD-MM-YYYY',
}
