/** @format */

import { put } from 'redux-saga/effects';
import * as actionList from 'store/reducers';
import { IHomeActionPayload } from 'types';
import { axiosClient } from 'utils';

export function* onPostBaseAction(action: IHomeActionPayload) {
  try {
    const { actionRequest, getDataSuccess } = actionList?.[action?.store] || {};
    const dataKey = action?.payload?.dataKey;
    yield put(actionRequest?.());
    const rs = yield axiosClient.post(`${action.payload.endPoint}`, action?.payload?.formData, {
      ...action.payload?.axiosConfig,
    });
    if (action?.callback) {
      yield put(getDataSuccess?.({}));

      action?.callback?.(rs);
      return;
    }
    const payload = dataKey
      ? {
          [dataKey]: action?.payload?.isObject ? rs?.data?.result?.[0] : rs?.data?.result,
        }
      : {};
    yield put(getDataSuccess?.(payload));
  } catch (e: any) {
    yield put(actionList?.[action?.store]?.getDataSuccess?.({}));
    if (action?.callback) {
      action?.callback?.({ success: false, ...e });
    }
  }
}
