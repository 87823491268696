/**
 * @format
 * @description define English language resource
 */

export default {
  txt: {
    manage_resident: 'Manage resident',
    resident: '{{0}} Resident',
    file_export: 'Export file',
    file_import: 'Import file',
    add: 'Add',
    search: 'Search',
    filter: 'Filter',
  },
  lable: {
    title_login: 'Login',
  },
  placeholder: {
    search: 'Search',
  },
  msg_confirm_delete_record: 'Are you sure confirm to delete {record} ?',

  msg_success01: 'Success',
  msg_name_required: 'Name is required',
  msg_must_is_number: '{name} must be number',

  lbl_username: 'User name',
  lbl_fullname: 'Full name',
  lbl_email: 'Email',
  lbl_account_bank: 'Bank account',
  lbl_birthday: 'Birthday',

  lbl_address: 'Address',
  lbl_group_driver: 'Group driver',
  lbl_city_name: 'City name',
  lbl_image: 'Image',
  lbl_confirm: 'Confirm',
  lbl_amount: 'Amount',
  lbl_deposit: 'Deposit',
  btn_with_draw: 'With draw',
  lbl_add_driver: 'Add group driver',
  lbl_add_driver_detail: 'Add group driver detail',
  lbl_edit_driver: 'Edit group driver',
  lbl_edit_driver_detail: 'Edit group driver detail',
  lbl_name: 'Name',
  lbl_sub_name: 'Sub name',
  lbl_avatar: 'Avatar',
  lbl_identification_front: 'Front Identification card',
  lbl_identification_back: 'Back Identification card',
  lbl_license_front: 'Front license',
  lbl_license_back: 'Back license',
  lbl_user_name: 'User name',
  lbl_full_name: 'Full name',
  lbl_phone_number: 'Phone number',
  lbl_password: 'Password',
  lbl_confirm_password: 'Confirm password',
  lbl_edit_user: 'Edit user',
  lbl_add_user: 'Add user',
  lbl_title: 'Title',
  lbl_content: 'Content',
  lbl_pending: 'Pending',
  lbl_success: 'Success',
  lbl_canceled: 'Canceled',
  lbl_transaction: 'Transaction',
  lbl_list_user: 'User list',
  lbl_group_driver_detail: 'Group driver detail',
  lbl_news: 'News',
  lbl_role: 'Role',
  lbl_user_request: 'User request',
  lbl_login: 'Login',
  lbl_welcome: 'Welcome to {value} Admin',
  lbl_user_edit_info: 'Edit user',
  lbl_user_add_info: 'Add user',
  lbl_user_list: 'User list',
  lbl_manage_user: 'Manage user',
  ROLE_ADMIN: 'Admin',
  ROLE_MANAGER: 'Manager',
  ROLE_DRIVER: 'Driver',
  ROLE_USER: 'User',
  lbl_user_count: '{value} user',
  lbl_change_language: 'Change language',
  lbl_language_en: 'English',
  lbl_language_vi: 'Tiếng việt',
  lbl_account: 'Account',
  lbl_total_count: 'Total {value}',
  lbl_logout: 'Logout',
  lbl_notification: 'Notification',
  lbl_customer_catalog: 'Customer catalog',
  lbl_support: 'Support',
  GENDER_MALE: 'Male',
  GENDER_FEMALE: 'Female',
  GENDER_UNKNOWN: 'Other',
  VEHICLE_MOTORBIKE: 'Motorbike',
  VEHICLE_CAR: 'Car',
  VEHICLE_NONE: 'None',
  lbl_vehicle: 'Vehicle',
  lbl_number_plate: 'Number plate',

  RESTAURANT_managers: 'Restaurant managers',
  RESTAURANT_name: "Restaurant's name",
  RESTAURANT_address: "Restaurant's address",
  RESTAURANT_open_time: 'Open time',
  RESTAURANT_stop_time: 'Close time',
  RESTAURANT_status: 'Status',
  RESTAURANT_status_active: 'Active',
  RESTAURANT_status_inactive: 'Inactive',
  lbl_filter: 'Filter',
  lbl_add_info: 'Create',
  lbl_edit_info: 'Edit information',
  RESTAURANT_openTime: {
    day: 'Opening day of the week',
    time: 'Open time',
  },
  RESTAURANT_location: {
    lat: 'Latitude',
    long: 'Longitude',
  },

  RESTAURANT_bankInformation: {
    bankName: 'Bank name',
    accountName: 'Account name',
    accountNumber: 'Account number',
  },
  lbl_manage_restaurant_catalog: 'Restaurant catalog',
  lbl_promotion: 'Promotion',
  lbl_type: 'Type',
  lbl_count: 'Count',
  lbl_queue_number: 'Queue number',
  lbl_icon: 'Icon',
  lbl_catalog_name: 'Catalog name',
  lbl_promotion_code: 'Promotion code',
  lbl_value: 'Value',
  lbl_status: 'Status',
  lbl_start_date: 'Start date',

  lbl_action: 'Actions',
  lbl_gender: 'Gender',

  btn_edit: 'Edit',
  btn_add: 'Add',
  btn_save: 'Save',
  btn_cancel: 'Cancel',
  btn_delete: 'Delete',
  btn_deposit: 'Deposit',
  btn_detail: 'Detail',
  btn_confirm: 'Confirm',
  btn_add_filter: 'Add filter',
  btn_reset: 'Reset',
  btn_previous: 'Previous',
  btn_next: 'Next',
  btn_view: 'View',
};
