/** @format */

import { Image } from 'antd';
import { IColumnsTableDefined, IBaseInput, IInputDatetime } from 'types';

export const columnsNotification: Partial<IColumnsTableDefined>[] = [
  {
    title: 'lbl_title',
    dataIndex: 'title',
    key: 'title',
    fixed: 'left',
  },
  {
    title: 'lbl_image',
    dataIndex: 'image',
    key: 'image',
    isElement: true,
    format(value) {
      return <Image src={`${process.env.REACT_APP_API_URL}/images/${value}`} />;
    },
  },
  {
    title: 'lbl_desc',
    dataIndex: 'desc',
    key: 'desc',
  },
];

export const inputFormNotification: (IBaseInput | IInputDatetime)[] = [
  {
    name: 'name',
    title: 'lbl_name',
    useI18nTitle: true,
    placeholder: 'lbl_name',
    useI18nPlaceholder: true,
    required: true,
    formItemProps: {
      rules: [{ required: true, message: 'msg_name_required', useI18n: true }],
    },
  },
  {
    name: 'desc',
    title: 'lbl_desc',
    useI18nTitle: true,
    placeholder: 'lbl_desc',
    useI18nPlaceholder: true,
  },
];

export const inputNotificationFilters = columnsNotification.map((item): IBaseInput => {
  return {
    key: item.key,
    title: item.title,
    useI18nTitle: true,
    placeholder: item.title,
    useI18nPlaceholder: true,
  };
});
export const dropdownFilterNotification = columnsNotification.map(item => {
  return {
    key: item.key,
    title: item.title,
    useI18nTitle: true,
  };
});
