/** @format */

import { IUser } from './user';

/**
 * @description the interface of user authentication entity
 * @export
 * @interface IAppState
 */

export interface IAppState {
  loading: boolean;
  error: any;
  language: ELanguage;
  isAuth: boolean;
  user: IUser | null;
}
export interface INofifyState {
  message: string;
  type: 'success' | 'info' | 'warning' | 'error';
  options: {
    position: {
      vertical: 'top' | 'bottom';
      horizontal: 'left' | 'center' | 'right';
    };
    autoHideDuration: number;
    useI18n: boolean;
  };
}

// export type IDriverSate = {};

export enum ELanguage {
  VI = 'vi',
  EN = 'en',
}
export interface IKeyAuth {
  accessToken: string;
  refreshToken?: string;
  updateParent?: boolean;
}

export interface IActionReducerWithCallback {
  payload: {
    callback: (oldState: Partial<any>, cb: (newState: Partial<any>) => void) => void;
  };
}

export type IPaginationClient = Record<'page' | 'pageSize' | 'totalCount', number>;
export type IPaginationServer = Record<'current' | 'total' | 'totalItems', number>;
