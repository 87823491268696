/** @format */

import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { DownOutlined, LogoutOutlined } from '@ant-design/icons';
import { Col, Row, Layout, Image, Dropdown, MenuProps, Badge } from 'antd';
import { logo } from 'assets/images';
import { Text } from 'components';
import './styles.scss';
import { useAuth, useCommon, useUser } from 'hooks';
import { EnumHeaderActions } from 'utils';
import { UserInfor } from './components';
import i18n from 'i18n';
import Icons from 'assets/icons';
const Header = () => {
  const { onLogout } = useAuth();
  const { onUpdateLayoutState, openSider } = useCommon();
  const { getUserProfile } = useUser();
  const [actions, setActions] = useState<EnumHeaderActions>();
  const handleMenuClick = useCallback(
    (action: EnumHeaderActions) => async value => {
      if (action === EnumHeaderActions.logout) {
        onLogout();
        return;
      }
      if (action === EnumHeaderActions.language) {
        await i18n.changeLanguage(value);
        return;
      }

      setActions(action);
    },
    [i18n.language],
  );
  const menuLanaguage = useMemo(
    () => [
      {
        label: (
          <Text onClick={() => handleMenuClick(EnumHeaderActions.language)('en')} useI18n>
            lbl_language_en
          </Text>
        ),
        key: '2.1',
      },
      {
        label: (
          <Text onClick={() => handleMenuClick(EnumHeaderActions.language)('vi')} useI18n>
            lbl_language_vi
          </Text>
        ),
        key: '2.2',
      },
    ],
    [handleMenuClick, i18n.language],
  );
  const profileMenu: MenuProps['items'] = useMemo(
    () => [
      // {
      //   label: (
      //     <Text onClick={handleMenuClick(EnumHeaderActions.account)} useI18n>
      //       lbl_account
      //     </Text>
      //   ),
      //   key: '1',
      //   icon: <UserOutlined />,
      // },
      {
        label: (
          <Text onClick={handleMenuClick(EnumHeaderActions.logout)} useI18n>
            lbl_logout
          </Text>
        ),
        key: '3',
        icon: <LogoutOutlined />,
      },
    ],
    [handleMenuClick],
  );
  const handleToggleSider = useCallback(() => {
    onUpdateLayoutState({
      openSider: !openSider,
    });
  }, [openSider]);

  const handleClose = useCallback(() => setActions(undefined), []);
  useEffect(() => {
    getUserProfile();
  }, []);
  return (
    <Layout.Header className='header-base'>
      <Row justify={'space-between'} wrap={false} className='f-width f-height'>
        <Col className='wrap-header-logo'>
          <Image
            src={logo}
            preview={false}
            height={35}
            className='header-logo'
            onClick={handleToggleSider}
          />
        </Col>
        <Row justify={'center'} align={'middle'}>
          <Badge dot className='cursor-pointer mr-6'>
            <Icons.BellOutlined className='bell-icon' />
          </Badge>
          <Dropdown menu={{ items: menuLanaguage }} placement={'bottomRight'}>
            <Row align={'middle'} justify={'center'} wrap={false} className='row-custom'>
              <Text className='font-14 text-left color-white font-12' useI18n>
                {`lbl_language_${i18n.language || 'vi'}`}
              </Text>
              <DownOutlined className='ml-1 font-12 text-left color-white ' />
              {/* <Text className=' text-left'>{userProfile?.data?.roles?.[0]?.name}</Text> */}
            </Row>
          </Dropdown>
          <Dropdown menu={{ items: profileMenu }} placement={'bottomRight'} className='ml-6'>
            <Row align={'middle'}>
              <Icons.UserOutlined />
              <Row justify={'center'} align={'middle'}>
                <DownOutlined className='ml-1 font-12 text-left color-white' />
              </Row>
            </Row>
          </Dropdown>
        </Row>
      </Row>
      {actions === EnumHeaderActions.account && (
        <UserInfor open={actions === EnumHeaderActions.account} onClose={handleClose} />
      )}
    </Layout.Header>
  );
};

export default memo(Header);
