/** @format */

import { Typography } from 'antd';
import React, { forwardRef, memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IText } from 'types';
import './styles.scss';
import i18n from 'i18n';
import { DataHelpers, EnumLanguage, EnumValueType } from 'utils';

const TextCus = forwardRef<HTMLSpanElement, IText>(function TextRef(
  { useI18n, children, replace, className = '', ...props },
  ref,
) {
  const { t } = useTranslation();
  const currentLange = i18n.language || EnumLanguage.vi;
  const result = useMemo(() => {
    const text = (useI18n && children ? t(children as string) : children) as string;
    if (!replace) return text;
    const { multiple, from = '', to = '' } = replace;
    return DataHelpers.getDataType(text) === EnumValueType.String
      ? text?.[multiple ? 'replaceAll' : 'replace']?.(from, `${to}`)
      : `${text}`;
  }, [useI18n, replace, children, currentLange]) as string;

  return (
    <Typography.Text ref={ref} {...props} className={`text-default font-14 ${className}`}>
      {result}
    </Typography.Text>
  );
});

export default memo(TextCus);
