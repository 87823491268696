/** @format */

import { EnumPrefixBaseActionType } from 'utils';
import { call } from 'redux-saga/effects';
import { onDeleteBaseActionsRequest } from './watch_del';
import { onGetBaseActionsRequest } from './watch_get';
import { onPostBaseAction } from './watch_post';
import { onPatchBaseAction } from './watch_patch';

export default function* watchRootSaga(action) {
  /* --------------Type format-------------- */
  /*   [store]/[CALL_ACION] ex: user/GET_BASE_ACTIONS */

  const [store, callAcion] = action.type?.split('/') || [];
  switch (callAcion) {
    case EnumPrefixBaseActionType.GET_BASE_ACTIONS:
      yield call(onGetBaseActionsRequest, { ...action, store });
      break;
    case EnumPrefixBaseActionType.POST_BASE_ACTIONS:
      yield call(onPostBaseAction, { ...action, store });
      break;
    case EnumPrefixBaseActionType.DELETE_BASE_ACTIONS:
      yield call(onDeleteBaseActionsRequest, { ...action, store });
      break;
    case EnumPrefixBaseActionType.PATCH_BASE_ACTIONS:
      yield call(onPatchBaseAction, { ...action, store });
      break;
    default:
      break;
  }
}
