/** @format */

import React, { useCallback } from 'react';
import { Col, Form, Image, Row } from 'antd';
import './styles.scss';
import { Button, Input, Text } from 'components';
import { TASLogo, bgLogin1 } from 'assets/images';
import { useAuth } from 'hooks';
import { useNavigate } from 'react-router-dom';

const Login = () => {
  const navigate = useNavigate();
  const { onLogin } = useAuth();
  const onSubmit = useCallback(payload => onLogin({ navigate, ...payload }), [navigate]);

  return (
    <Col className='f-width f-height login-page d-flex justiy-center'>
      <Row className='f-width d-flex align-center justify-center'>
        <Col className='login-left d-flex align-center'>
          <Col>
            <Text
              useI18n
              className='title-login'
              replace={{
                from: '{value}',
                to: 'Go Fast',
              }}
            >
              lbl_welcome
            </Text>
            <Row className='justify-center wrap-image'>
              <Image src={bgLogin1} preview={false} className='d-flex' />
            </Row>
          </Col>
        </Col>
        <Col className='right-conent'>
          <Col className='content'>
            <Col className='d-flex align-center justify-center'>
              <Image src={TASLogo} preview={false} className='d-flex' />
            </Col>
            <Col className='d-flex align-center justify-center'>
              <Text useI18n className='title-login'>
                lbl_login
              </Text>
            </Col>

            <Col className='d-flex align-center justify-center'>
              <Text useI18n className='login-intro'>
                Chào mừng bạn đã đến với trang quản trị của T.A.S
              </Text>
            </Col>

            <Form className='form-login pr-4 pl-4 mt-4' onFinish={onSubmit}>
              <Input
                title='lbl_phone_number'
                useI18nTitle
                formItemProps={{
                  name: 'phoneNumber',
                  className: 'mt-2',
                  rules: [{ required: true }],
                }}
              />
              <Input
                title='lbl_password'
                useI18nTitle
                type='password'
                allowClear={false}
                formItemProps={{
                  name: 'password',
                  className: 'mt-2',
                  rules: [{ required: true, min: 6 }],
                }}
              />
              <Button
                htmlType='submit'
                title='btn_confirm'
                useI18n
                className='f-width mt-2 btn-login'
              />
            </Form>
          </Col>
        </Col>
      </Row>
    </Col>
  );
};
export default Login;
