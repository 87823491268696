/** @format */

import React from 'react';
import { Row, Spin } from 'antd';

function ActivityIndicator() {
  return (
    <Row>
      <Spin size='large' />
    </Row>
  );
}
export default ActivityIndicator;
