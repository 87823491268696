/** @format */

import React, { FC, memo, useCallback, useEffect, useState } from 'react';
import { Header } from './Header';
import { Col, Form, Row } from 'antd';
import { Input } from 'components';
import {
  EnumActionForm,
  EnumInputVariant,
  inputAddUsersList,
  inputUsersUpload,
  optionDriverVehicle,
  // listInputUploadIdentification,
  // listInputUploadLicense,
} from 'utils';
import { useParams } from 'react-router-dom';
import { useCommon, useUser } from 'hooks';
import { useTranslation } from 'react-i18next';

interface IResidentEditPage {
  open?: boolean;
  onClose?: () => void;
  onRefresh?: () => void;
  record?: any;
  onActionClick?: (action: EnumActionForm) => void;
}
const ResidentEdit: FC<IResidentEditPage> = ({ onActionClick, record }) => {
  const { id } = useParams();
  const { onAdminCreateUser } = useUser();
  const { t } = useTranslation();
  const { onGetImageCDN } = useCommon();
  const [formAntd] = Form.useForm();
  const role = Form.useWatch('role', formAntd);
  // const vehicle = Form.useWatch('vehicle', formAntd);
  const [previewImage, setPreviewImage] = useState<Record<string, string>>();
  const handleSubmit = useCallback(
    payload => {
      const form = record ? { id: record.id, ...payload } : { ...payload };
      onAdminCreateUser(form, t, () => {
        onActionClick?.(EnumActionForm.cancel);
      });
    },
    [formAntd, record, id],
  );
  const handleClick = useCallback(
    (action: EnumActionForm) => {
      if (action === EnumActionForm.save) {
        formAntd.submit();
        return;
      }
      onActionClick?.(action);
    },
    [formAntd],
  );

  const handleSelectImage = useCallback(
    (key: string) =>
      ({ file: { originFileObj } }) => {
        setPreviewImage(old => ({ ...old, [key]: URL.createObjectURL(originFileObj) }));
        formAntd.setFieldValue(key, originFileObj);
      },
    [formAntd],
  );
  // const handleChange = useCallback(
  //   (key?: string) => (_, second) => {
  //     key === 'birthday' && formAntd.setFieldValue(key, second);
  //   },
  //   [formAntd],
  // );
  const renderInput = useCallback(
    inp => (
      <Input
        key={inp.name}
        {...inp}
        showUploadList={false}
        onChange={handleSelectImage(inp.name || '') as any}
        fileList={[
          {
            ...formAntd.getFieldValue(inp.name || ''),
            url: previewImage?.[inp.name || ''],
          },
        ]}
      >
        {previewImage?.[inp.name || ''] && (
          <img src={previewImage?.[inp.name || '']} alt={inp.name} style={{ width: '100%' }} />
        )}
      </Input>
    ),
    [previewImage, formAntd, handleSelectImage],
  );
  useEffect(() => {
    if (record) {
      const dataForGetImage = [
        { key: 'avatar', recordKey: ['avatar'] },
        { key: 'identificationFront', recordKey: ['identification', 'front_image'] },
        { key: 'identificationBack', recordKey: ['identification', 'back_image'] },
        { key: 'licenseFront', recordKey: ['license', 'front_image'] },
        { key: 'licenseBack', recordKey: ['license', 'back_image'] },
      ];
      dataForGetImage.forEach(item => {
        const [key1, key2] = item.recordKey;
        const path = key2 ? record?.[key1]?.[key2] : record?.[key1];
        path &&
          onGetImageCDN({ path }, res => {
            setPreviewImage(old => ({ ...old, [item.key]: res }));
          });
      });
    }
  }, [record]);

  return (
    <Col className='page-form'>
      <Header onActionClick={handleClick} record={record} />
      <Form
        onFinish={handleSubmit}
        initialValues={{
          role: 'USER',
          gender: 'UNKNOWN',
          vehicle: 'NONE',
          ...(record ? { ...record } : { password: 'user@123' }),
          fullName: record?.full_name,
          userName: record?.user_name,
          phoneNumber: record?.phone_number,
          accountBank: record?.account_bank,
          numberPlate: record?.number_plate,

          // identificationFront: record?.identification?.front_image,
          // identificationBack: record?.identification?.back_image,
          // licenseFront: record?.license?.front_image,
          // licenseBack: record?.license?.back_image,
        }}
        form={formAntd}
        className='form-content'
      >
        <Row className='avatar-upload'>{inputUsersUpload?.map(renderInput)}</Row>
        <Row>
          {inputAddUsersList
            ?.filter(item => !record || item?.['name'] !== 'password')
            ?.map(input => (
              <Input
                {...input}
                key={input?.['name']}
                // onChange={handleChange(input?.['name']) as any}
                containerClass='mr-2 haft-width'
              />
            ))}
          <Input
            variant={EnumInputVariant.combobox}
            optionsDefine={optionDriverVehicle}
            name='vehicle'
            title='lbl_vehicle'
            placeholder='lbl_vehicle'
            useI18nTitle
            useI18nPlaceholder
            containerClass='mr-2 haft-width'
          />
          {role === 'DRIVER' /* && vehicle !== 'NONE' */ && (
            <Input
              required
              name='numberPlate'
              title='lbl_number_plate'
              placeholder='lbl_number_plate'
              useI18nTitle
              useI18nPlaceholder
              containerClass='mr-2 haft-width'
              formItemProps={{
                rules: [{ required: true }],
              }}
            />
          )}
        </Row>
      </Form>
    </Col>
  );
};

export default memo(ResidentEdit);
